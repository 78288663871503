/* eslint-disable */
import { Dispatch, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import './ZiChart.scss';
import {
	Button,
	Checkbox,
	FormGroup,
	NumberInput,
	Popover,
	Tooltip,
	Text,
} from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	fa1,
	fa2,
	faChartArea,
	faChartBar,
	faChartLineUp,
	faChartPyramid,
	faChartScatter,
	faChartScatterBubble,
	faChartSimple,
	faChartSimpleHorizontal,
	faChevronCircleUp,
	faChevronsDown,
	faCloudWord,
	faCube,
	faDiagramNested,
	faDiagramSankey,
	faFilter,
	faGauge,
	faGear,
	faHeat,
	faInfoCircle,
	faLightbulb,
	faLollipop,
	faPalette,
	faPieChart,
	faCircleDot,
	faWarning,
	faSlider,
} from '@fortawesome/pro-regular-svg-icons';
import { ChartBuilder, returnHighChartsChartType } from '../../builders/chart-builder';
import Loader from '../util/Loader';
import { useHighChart } from '../../hooks/HighChartHooks';
import { ChartSeries, ChartSeriesChartTypes } from '../../types/charts/chart-options';
import { ColorResult, TwitterPicker } from 'react-color';
import tinycolor from 'tinycolor2';
// import { TChart } from '../../api/types/chart/chart';
import EmptyResults from '../EmptyResults';
import { TUnitType, UnitType } from '../../api/analytics/UnitType';
import { useParams } from 'react-router-dom';
import { DraggableMenuItemData, Size } from '../../types/databuilder/databuilder';
import { TDataframeData, TDataframeEntry, TDataframeOrder } from '../../api/types';
import {
	TNewDataframeData,
	TNewDataframeEntry,
	TNewDataframeOrder,
} from '../../api/dataframes/Dataframes';
import { getChartSeriesData } from '../../helpers/chart.helper';
import { ZiChartSettings } from '../../types/charts/chart-settings';
import { TKeyMeasureFact } from '../../api/analytics/KeyMeasureFact';
import { TChart } from '../../api/charts/Chart';
import { useMount } from 'react-use';
import { useApplication } from '../user/ApplicationProvider';

export type ZiChartProps = {
	isConfigMode: boolean;
	displayChart?: boolean;
	title?: string;
	valueTitle?: string;
	categoryTitle: string;
	chartRetrevialData: any[];
	chartData?: TChart;
	chartType?: ChartSeriesChartTypes;
	setChartType?: Dispatch<SetStateAction<ChartSeriesChartTypes>>;
	secondChartType?: ChartSeriesChartTypes;
	setSecondChartType?: Dispatch<SetStateAction<ChartSeriesChartTypes>>;
	setChartSettings: Dispatch<SetStateAction<ZiChartSettings>>;
	chartSettings: ZiChartSettings;
	isLoading?: boolean;
	transparentBackground?: boolean;
	size?: Size | undefined;
	setChart?: Dispatch<SetStateAction<TChart | null>>;
	setChartRetrevialData?: Dispatch<SetStateAction<any[] | undefined>>;
	unitType?: TUnitType;
	setSelectedUnitType?: Dispatch<SetStateAction<TUnitType | undefined>>;
	selectedUnitType?: TUnitType;
	selected2ndUnitType?: TUnitType;
	selectedNumDecimals?: number;
	selected2ndNumDecimals?: number;
	setHasLoadedSideBar?: Dispatch<SetStateAction<boolean>>;
	isSingleSeries?: boolean;
	containerProps?: any;
	setDrillSequenceOpen?: Dispatch<SetStateAction<boolean>>;
	setInsightsOpen?: Dispatch<SetStateAction<boolean>>;
	setMustResetSelectedChart?: Dispatch<SetStateAction<boolean>>;
	mustResetSelectedChart?: boolean;
	droppedFieldsCount?: number;
	isExistingChart?: boolean;
	isDataRetrievalLoading?: boolean;
	ApplyDrilldownDrillIn?: (name: string, isKMF: boolean) => void;
	drillLevel?: number;
	resetDrillSequence?: () => void;
	keyMeasureFact?: TKeyMeasureFact | null | undefined;
	sethasMultipleRecords?: Dispatch<SetStateAction<boolean>>;
	setIsOdometer?: Dispatch<SetStateAction<boolean>>;
	showLimitWarning?: boolean;
	setShowLimitWarning?: Dispatch<SetStateAction<boolean>>;
	grandTotal?: [{ [key: string]: number }];
	droppedFacts?: DraggableMenuItemData[];
	unitTypes?: TUnitType[];
};

const ZiChart = (props: ZiChartProps) => {
	// hooks
	const { options, setOptions, chartRender } = useHighChart();

	// region state
	const [chartTypeOpen, setChartTypeOpen] = useState(false);
	const [, setDrillSequenceOpen] = useState(false);
	const [, setColorsOpen] = useState(false);
	const [, setChartSettingsOpen] = useState(false);
	const [, setInsightsPinned] = useState(false);

	// chart settings
	const [backgroundColor, setBackgroundColor] = useState('#fff');
	const [chartSeriesColor1, setChartSeriesColor1] = useState('#0693E3');
	const [chartSeriesColor2, setChartSeriesColor2] = useState('#bcbcbe');
	const [hasLoadedChartOptions, setHasLoadedChartOptions] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);

	// chart state
	const [title, setTitle] = useState<string>(props.title ?? '');
	const [categoryTitle, setCategoryTitle] = useState<string>(props.categoryTitle ?? '');
	const [categories] = useState<string[]>([]);
	const [seriesData, setSeriesData] = useState<ChartSeries[]>([]);
	const [_dynamicChartSeries, _setDynamicChartSeries] =
		useState<[number[], number[], number[]]>();
	const [xAxisArray, setXaxisArray] = useState<string[]>([]);
	const [yAxisArray, setYaxisArray] = useState<string[]>([]);
	const [lookupArray, setLookupArray] = useState<(string | number)[][]>([]);
	const [selectedChartTypeButton, setSelectedChartTypeButton] = useState<string>();
	const [executionCount, setExecutionCount] = useState(0);
	const { chartId } = useParams();
	const [currentChartSeriesNumber, setCurrentChartSeriesNumber] = useState(1);

	useMount(() => {
		initChartColors();
	});

	useEffect(() => {
		setIsLoading(true);
		initChartColors();
		BuildDynamicChartSeries();
		setIsLoading(false);
	}, [
		props.setChart,
		props.setChartRetrevialData,
		props.chartRetrevialData,
		props.chartType,
		props.secondChartType,
		props.setSelectedUnitType,
		props.selectedUnitType,
		props.selectedNumDecimals,
		props.droppedFacts?.length,
		chartSeriesColor1,
		chartSeriesColor2,
		props.chartSettings?.showPercentages,
	]);

	const BuildDynamicChartSeries = () => {
		const dbChartType = props.chartData?.chart_type
			? (props.isSingleSeries && (props.chartData?.chart_type as string)) == 'solidgauge'
				? 'odometer'
				: (props.chartData?.chart_type as ChartSeriesChartTypes)
			: props.chartType
			? props.chartType
			: 'column';

		const isSingleSeries =
			props.isSingleSeries ??
			[
				'bar',
				'column',
				'scatter',
				'wordcloud',
				'line',
				'packedbubble',
				'funnel',
				'area',
				'lollipop',
				'pyramid',
				'pie',
				'donut',
				'odometer',
				'columnrange',
			].includes(dbChartType!!);

		const defaultUnitType = UnitType.Default();

		const yAxisFirstUnitType =
			props.droppedFacts && props.droppedFacts.length > 0
				? props.unitTypes?.find((x) =>
						props.droppedFacts ? x.id == props.droppedFacts[0].data?.unitType : 0
				  )
				: (defaultUnitType as TUnitType);

		const yAxisSecondUnitType =
			props.droppedFacts && props.droppedFacts.length > 1
				? props.unitTypes?.find((x) =>
						props.droppedFacts ? x.id == props.droppedFacts[1].data?.unitType : 0
				  )
				: (defaultUnitType as TUnitType);

		const chartSeriesData = getChartSeriesData(
			props.chartRetrevialData,
			isSingleSeries,
			selectedChartTypeButton ? selectedChartTypeButton : dbChartType,
			yAxisFirstUnitType,
			yAxisSecondUnitType,
			props.selectedNumDecimals,
			props.droppedFacts?.length,
			chartSeriesColor1,
			props.grandTotal,
			props.chartSettings.showPercentages,
			props.droppedFacts
		);

		setXaxisArray(chartSeriesData.xAxisArray);
		if (chartSeriesData.yAxisArray && chartSeriesData.yAxisArray.length > 0) {
			setYaxisArray(chartSeriesData.yAxisArray);
		}

		props.setIsOdometer && props.setIsOdometer(props.chartType === 'odometer');

		if (chartSeriesData.seriesData[0]?.data?.length > 1) {
			props.sethasMultipleRecords && props.sethasMultipleRecords(true);
		} else {
			props.sethasMultipleRecords && props.sethasMultipleRecords(false);
		}

		if (props.droppedFacts && props.droppedFacts.length > 1) {
			const isBothYaxisColumn =
				props.secondChartType === 'bar' && props.chartType == 'column';
			const isBothYaxisBar = props.secondChartType === 'bar' && props.chartType == 'bar';

			if (isBothYaxisColumn) {
				chartSeriesData.seriesData.map((data, idx) => {
					if (idx == 0) {
						data.type = 'bar';
						data.color = chartSeriesColor1;
					}
					if (idx == 1) {
						data.type = 'bar';
						data.color = chartSeriesColor2;
						data.dataLabels = { ...data.dataLabels, color: 'black' };
					}
				});
			} else if (isBothYaxisBar) {
				chartSeriesData.seriesData.map((data, idx) => {
					if (idx == 0) {
						data.type = 'column';
						data.color = chartSeriesColor1;
					}
					if (idx == 1) {
						data.type = 'column';
						data.color = chartSeriesColor2;
						data.dataLabels = { ...data.dataLabels, color: 'black' };
					}
				});
			} else {
				chartSeriesData.seriesData.map((data, idx) => {
					const actualChartType = returnHighChartsChartType(props.chartType);
					if (idx == 0) {
						data.type = actualChartType as
							| 'bar'
							| 'column'
							| 'scatter'
							| 'wordcloud'
							| 'line'
							| 'packedbubble'
							| 'columnrange'
							| 'funnel'
							| 'area'
							| 'lollipop'
							| 'pyramid'
							| 'solidgauge'
							| 'heatmap'
							| 'sankey'
							| 'dependencywheel'
							| undefined;
						data.color = chartSeriesColor1;
					}
					if (idx == 1) {
						if (props.secondChartType === 'bar') {
							data.type = 'column';
						}
						if (props.secondChartType === 'line') {
							data.type = 'line';
						}
						data.color = chartSeriesColor2;
						data.dataLabels = { ...data.dataLabels, color: 'black' };
					}
				});
			}
		}

		setSeriesData(chartSeriesData.seriesData);
		setLookupArray(chartSeriesData.lookupData);
	};

	useEffect(() => {
		setTitle(props.title ?? '');
	}, [props.title, props.valueTitle, props.categoryTitle]);

	//workaround for heatmap not loading series data when chart changed to multi-series
	useEffect(() => {
		setChartTypeOpen(true);
		setTimeout(() => {
			setChartTypeOpen(false);
		}, 50);
	}, [props.droppedFieldsCount]);

	const initChartColors = () => {
		let updatedChartColors =
			props.chartSettings.chartColor != ''
				? props.chartSettings.chartColor.trim().replaceAll(' ', '').split('|')
				: [];

		//first check to see if the color pallete is for chart with multiple facts dropped in (multi-fact chart)
		if (
			updatedChartColors.length > 0 &&
			updatedChartColors.length == 2 &&
			props.chartType !== 'clusteredbar' &&
			props.chartType !== 'clusteredcolumn'
		) {
			const series1Color = updatedChartColors[0];
			const series2Color = updatedChartColors[1];

			setChartSeriesColor1(series1Color);
			setChartSeriesColor2(series2Color);
		} else if (
			updatedChartColors.length > 0 &&
			props.chartType !== 'clusteredbar' &&
			props.chartType !== 'clusteredcolumn'
		) {
			const series1Color = updatedChartColors[0];
			setChartSeriesColor1(series1Color);
		}
	};

	// Function to update chart options
	const updateChartOptions = () => {
		const defaultChartType = props.isSingleSeries ? 'column' : 'clusteredcolumn';
		const dbChartType =
			props.chartType != undefined
				? props.isSingleSeries && (props.chartType as string) == 'solidgauge'
					? 'odometer'
					: props.chartType
				: defaultChartType;
		const isHeatmapChart = props.chartType == 'heatmap';

		let chartOptionsBuilder = new ChartBuilder()
			.withTitle(props.chartSettings.hideTitle ? '' : title)
			.withCategoryTitle(props.categoryTitle)
			.withValueCategories(xAxisArray ?? [], yAxisArray ?? [], isHeatmapChart)
			.withSeries(
				dbChartType,
				seriesData,
				chartSeriesColor1,
				!props.isSingleSeries,
				props.unitType,
				props.selected2ndUnitType,
				props.ApplyDrilldownDrillIn && props.ApplyDrilldownDrillIn,
				lookupArray,
				props.keyMeasureFact,
				props.secondChartType === 'line',
				props.chartSettings.showPercentages,
				props.selectedNumDecimals,
				props.droppedFacts
			);

		const showSecondYaxis = props.droppedFacts
			? props.droppedFacts.length > 1 && dbChartType !== 'columnrange'
			: false;

		if (isHeatmapChart) {
			const { lightenedHex, darkenedHex } = getShadedColors(chartSeriesColor1, 18, 40);
			chartOptionsBuilder = chartOptionsBuilder.withColorAxis({
				minColor: lightenedHex,
				maxColor: darkenedHex,
			});
		}

		const showLegend =
			props.chartType == 'sankey' || props.chartType == 'dependencywheel'
				? false
				: !props.chartSettings.hideLegend;

		const seriesHeaders =
			props.chartRetrevialData && props.chartRetrevialData.length > 0
				? Object.keys(props.chartRetrevialData[0])
				: [];

		const yAxisFirstUnitType =
			props.droppedFacts && props.droppedFacts.length > 0
				? props.unitTypes?.find((x) =>
						props.droppedFacts ? x.id == props.droppedFacts[0].data?.unitType : 0
				  )?.name
				: '';

		const yAxisFirstHeaderValue =
			seriesHeaders.length > 2 && props.droppedFacts && props.droppedFacts.length == 1 //Check if multi-series chart (2 series and a fact)
				? seriesHeaders[1].replace(/([a-z])([A-Z])/g, '$1 $2')
				: yAxisFirstUnitType;

		const xAxisFirstHeaderValue =
			seriesHeaders.length > 1 ? seriesHeaders[0].replace(/([a-z])([A-Z])/g, '$1 $2') : '';

		const yAxisSecondUnitType =
			props.droppedFacts && props.droppedFacts.length > 1
				? props.unitTypes?.find((x) =>
						props.droppedFacts ? x.id == props.droppedFacts[1].data?.unitType : 0
				  )
				: '';
		const yAxisSecondHeaderValue = yAxisSecondUnitType ? yAxisSecondUnitType.name : '';

		chartOptionsBuilder = chartOptionsBuilder
			.withDataLabels(props.chartSettings.showDataValues, showLegend, dbChartType)
			.withScrollbar(props.chartSettings.showScrollbar)
			.withValueAxisLabelsShown(
				!props.chartSettings.hideYAxis,
				!props.chartSettings.hideXAxis,
				seriesData,
				props.unitType,
				props.selected2ndUnitType,
				props.isSingleSeries,
				dbChartType,
				showSecondYaxis,
				yAxisFirstHeaderValue,
				yAxisSecondHeaderValue,
				xAxisFirstHeaderValue,
				props.chartSettings.showPercentages,
				props.selectedNumDecimals,
				props.selected2ndNumDecimals,
				props.droppedFacts
			);

		if (props.transparentBackground) {
			chartOptionsBuilder.withTransparentBackground();
		}

		if (props.size) {
			chartOptionsBuilder.withSize(props.size.height, props.size.width);
		}

		setOptions(chartOptionsBuilder.build());
	};

	const updateChartOptionsWrapper = () => {
		// Call the initial logic
		updateChartOptions();
		setHasLoadedChartOptions(true);
	};

	// No need to set loading for these chart option changes
	useEffect(() => {
		updateChartOptionsWrapper();
	}, [
		props.chartType,
		backgroundColor,
		props.chartSettings,
		props.chartRetrevialData,
		seriesData,
		props.valueTitle,
		chartSeriesColor1,
	]);

	useEffect(() => {
		if (executionCount >= 2) {
			setTimeout(() => {
				if (isLoading) {
					setIsLoading(false);
				}
			}, 600);
			setExecutionCount(0);
		}
	}, [executionCount]);

	useEffect(() => {
		const newOptions = { ...options };
		if (newOptions.chart && props.size?.height) {
			newOptions.height = props.size?.height;
			newOptions.width = props.size?.width;
		}
		setOptions({ ...newOptions });
	}, [props.size]);

	useEffect(() => {
		setTimeout(() => {
			// Remove class from all other buttons
			const buttons = document.getElementsByClassName('btn-charttype');
			const buttonElements = Array.from(buttons) as HTMLButtonElement[];
			buttonElements.map((x) => {
				x.classList.remove('btn-chart-selected');
			});

			const chartTypeHasChanged =
				props.chartType && props.chartType != props.chartData?.chart_type;
			const chartType = props.chartData?.chart_type;
			const isMultiseries = !props.isSingleSeries;

			//reset single series column as active button
			let currentButton = document.getElementById('column_Ref_chart-simple');

			if (!chartType) {
				setSelectedChartTypeButton('column');
			}

			if (isMultiseries && chartTypeHasChanged) {
				//reset multi series column as active button
				currentButton = document.getElementById('clusteredcolumn_Ref_chart-simple');
				setSelectedChartTypeButton('clusteredcolumn');
			} else if (props.chartType) {
				setSelectedChartTypeButton(props.chartType);

				currentButton = document.getElementsByName(props.chartType)[0];
			}

			currentButton && currentButton.classList.add('btn-chart-selected');

			props.setMustResetSelectedChart && props.setMustResetSelectedChart(false);
		}, 50);
	}, [chartTypeOpen]);

	const ChartTypeButton_Click = (
		event: MouseEvent | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
		type: string | undefined,
		name: string
	): void => {
		// Set chart type
		props.setChartType && props.setChartType(type as ChartSeriesChartTypes);

		// Remove class from all other buttons
		const buttons = document.getElementsByClassName('btn-charttype');
		const buttonElements = Array.from(buttons) as HTMLButtonElement[];
		buttonElements.map((x) => {
			x.classList.remove('btn-chart-selected');
		});

		const currentButton = event.currentTarget as HTMLButtonElement;
		setSelectedChartTypeButton(name);
		currentButton && currentButton.classList.add('btn-chart-selected');
	};

	// endregion state

	// region tooltips
	// chart config
	const chartTypeTooltipRef = useRef();
	const drilldownTooltipRef = useRef();
	const colorsTooltipRef = useRef();
	const chartSettingsTooltipRef = useRef();
	const insightsTooltipRef = useRef();

	// chart types
	const barTooltip = useRef();
	const columnTooltip = useRef();
	const scatterTooltip = useRef();
	const wordCloudTooltip = useRef();
	const lineTooltip = useRef();
	const pieTooltip = useRef();
	const donutTooltip = useRef();
	const packedBubbleTooltip = useRef();
	const funnelTooltip = useRef();
	const areaTooltip = useRef();
	const lollipopTooltip = useRef();
	const pyramidTooltip = useRef();
	const odometerTooltip = useRef();
	const columnRangeTooltip = useRef();
	const heatmapTooltip = useRef();
	const stackedBarTooltip = useRef();
	const clusteredlineTooltip = useRef();
	const stackedColumnTooltip = useRef();
	const clusteredBarTooltip = useRef();
	const clusteredColumnTooltip = useRef();
	const sankeyTooltip = useRef();
	const depWheelTooltip = useRef();
	// endregion tooltips

	const toggleItem = (
		key:
			| 'type'
			| 'drill'
			| 'colors'
			| 'settings'
			| 'insights'
			| 'drillOut'
			| 'secondYaxisBar'
			| 'secondYaxisLine'
	): void => {
		switch (key) {
			case 'type':
				setChartTypeOpen((prev) => !prev);
				break;
			case 'drill':
				props.setDrillSequenceOpen && props.setDrillSequenceOpen((prev) => !prev);
				break;
			case 'colors':
				setColorsOpen((prev) => !prev);
				break;
			case 'settings':
				setChartSettingsOpen((prev) => !prev);
				break;
			case 'insights':
				props.setInsightsOpen && props.setInsightsOpen((prev) => !prev);
				break;
			case 'drillOut':
				props.resetDrillSequence && props.resetDrillSequence();
				break;
			case 'secondYaxisBar':
				props.setSecondChartType && props.setSecondChartType('bar');
				break;
			case 'secondYaxisLine':
				props.setSecondChartType && props.setSecondChartType('line');
				break;
		}
	};

	const handleChangeComplete = (color: ColorResult) => {
		const { hex } = color;
		setBackgroundColor(hex);

		const { lightenedHex, darkenedHex } = getShadedColors(hex, 18, 40);

		if (currentChartSeriesNumber == 1) {
			setChartSeriesColor1(hex);

			if (props.chartSettings.chartColor.includes('|')) {
				const splitColors = props.chartSettings.chartColor.trim().split('|');
				const secondSeriesColors = splitColors[1];

				props.setChartSettings &&
					props.setChartSettings({
						...props.chartSettings,
						chartColor: hex + ' | ' + secondSeriesColors,
					});
			} else {
				props.setChartSettings &&
					props.setChartSettings({
						...props.chartSettings,
						chartColor: hex,
					});
			}
		}

		if (currentChartSeriesNumber == 2) {
			setChartSeriesColor2(hex);

			const existingChartSeriesColor = props.chartSettings.chartColor;

			if (props.chartSettings.chartColor.includes('|')) {
				const splitColors = existingChartSeriesColor.trim().split('|');
				const firstSeriesColor = splitColors[0].trim();
				props.setChartSettings &&
					props.setChartSettings({
						...props.chartSettings,
						chartColor: firstSeriesColor + ' | ' + hex,
					});
			} else {
				props.setChartSettings &&
					props.setChartSettings({
						...props.chartSettings,
						chartColor: props.chartSettings.chartColor + ' | ' + hex,
					});
			}
		}
	};

	function getShadedColors(hexColor: string, lightenAmount: number, darkenAmount: number) {
		const baseColor = tinycolor(hexColor);

		// Lighten the color
		const lightenedColor = baseColor.lighten(lightenAmount);
		const lightenedHex = lightenedColor.toHexString();

		// Darken the color
		const darkenedColor = baseColor.darken(darkenAmount);
		const darkenedHex = darkenedColor.toHexString();

		return { lightenedHex, darkenedHex };
	}

	let singleSeries = [
		{
			type: 'bar',
			icon: faChartSimpleHorizontal,
			ref: barTooltip,
			name: 'bar',
		},
		{
			type: 'column',
			icon: faChartSimple,
			ref: columnTooltip,
			name: 'column',
		},
		{
			type: 'scatter',
			icon: faChartScatter,
			ref: scatterTooltip,
			name: 'scatter',
		},
		{
			type: 'wordcloud',
			icon: faCloudWord,
			ref: wordCloudTooltip,
			name: 'wordcloud',
		},
		{ type: 'line', icon: faChartLineUp, ref: lineTooltip, name: 'line' },
		{ type: 'pie', icon: faPieChart, ref: pieTooltip, name: 'pie' },
		{ type: 'donut', icon: faCircleDot, ref: donutTooltip, name: 'donut' },
		{
			type: 'packedbubble',
			icon: faChartScatterBubble,
			ref: packedBubbleTooltip,
			name: 'scatterPlot',
		},
		{ type: 'funnel', icon: faFilter, ref: funnelTooltip, name: 'funnel' },
		{ type: 'area', icon: faChartArea, ref: areaTooltip, name: 'area' },
		{
			type: 'lollipop',
			icon: faLollipop,
			ref: lollipopTooltip,
			name: 'lollipop',
		},
		{
			type: 'pyramid',
			icon: faChartPyramid,
			ref: pyramidTooltip,
			name: 'pyramid',
		},
	];

	singleSeries = [
		...singleSeries,
		...(xAxisArray.length == 1
			? [
					{
						type: 'odometer',
						icon: faGauge,
						ref: odometerTooltip,
						name: 'odometer',
					},
			  ]
			: []),
	];

	singleSeries = [
		...singleSeries,
		...(props.droppedFacts && props.droppedFacts.length > 1
			? [
					{
						type: 'columnrange',
						icon: faSlider,
						ref: columnRangeTooltip,
						name: 'columnrange',
					},
			  ]
			: []),
	];

	// region popovers

	const chartTypeButton = (
		<Popover
			headerContent={'Chart Type'}
			bodyContent={
				<>
					<span>Single Series</span>
					<div className="chart-type-container">
						{singleSeries.map((button, index) => (
							<Button
								className={`btn-charttype ${
									selectedChartTypeButton === button.name
										? 'btn-chart-selected'
										: ''
								}`}
								id={`${button.type}_Ref_${button.icon.iconName}`}
								key={index}
								name={button.type}
								ref={button.ref}
								isDisabled={!props.isSingleSeries}
								onClick={(event) =>
									ChartTypeButton_Click(
										event,
										button.type as ChartSeriesChartTypes,
										button.name
									)
								}
							>
								<FontAwesomeIcon icon={button.icon} />
							</Button>
						))}
					</div>
					<span>Multi Series</span>
					<div className="chart-type-container">
						{[
							{ type: 'heatmap', icon: faHeat, ref: heatmapTooltip, name: 'heatmap' },
							{
								type: 'stackedcolumn',
								icon: faChartSimple,
								ref: stackedColumnTooltip,
								name: 'stackedcolumn',
							},
							{
								type: 'stackedbar',
								icon: faChartSimpleHorizontal,
								ref: stackedBarTooltip,
								name: 'stackedbar',
							},
							{
								type: 'clusteredline',
								icon: faChartLineUp,
								ref: clusteredlineTooltip,
								isClustered: true,
								name: 'clusteredline',
							},
							{
								type: 'clusteredcolumn',
								icon: faChartSimple,
								ref: clusteredColumnTooltip,
								isClustered: true,
								name: 'clusteredcolumn',
							},
							{
								type: 'clusteredbar',
								icon: faChartSimpleHorizontal,
								ref: clusteredBarTooltip,
								isClustered: true,
								name: 'clusteredbar',
							},
							{
								type: 'sankey',
								icon: faDiagramSankey,
								ref: sankeyTooltip,
								name: 'sankey',
							},
							{
								type: 'dependencywheel',
								icon: faDiagramNested,
								ref: depWheelTooltip,
								name: 'dependencywheel',
							},
						].map((button, index) => (
							<Button
								id={`${button.type}_Ref_${button.icon.iconName}`}
								key={index}
								name={button.type}
								ref={button.ref}
								isDisabled={props.isSingleSeries}
								onClick={(event) =>
									ChartTypeButton_Click(
										event,
										button.type as ChartSeriesChartTypes,
										button.name
									)
								}
								className={`btn-charttype ${
									button.isClustered ? 'dv-clustered' : ''
								} ${
									selectedChartTypeButton === button.name
										? ' btn-chart-selected'
										: ''
								}`}
							>
								<FontAwesomeIcon icon={button.icon} />
								{button.isClustered && <FontAwesomeIcon icon={faCube} />}
							</Button>
						))}
					</div>

					{props.droppedFacts &&
						props.droppedFacts.length > 1 &&
						props.chartType !== 'columnrange' && (
							<>
								<br />
								<span>Second Y-Axis Chart Type</span>
								<div className="chart-type-container">
									<Tooltip content={'Toggle Second Y-Axis Bar Chart'}>
										<Button
											id="toggleSecondYAxisBarChart"
											data-testid="toggleSecondYAxisBarChart"
											className={`${
												props.secondChartType === 'bar'
													? 'btn-yaxis-selected btn-charttype'
													: 'btn-charttype'
											}`}
											onClick={(e) => {
												toggleItem('secondYaxisBar');
											}}
										>
											<FontAwesomeIcon icon={faChartBar} />
										</Button>
									</Tooltip>

									<Tooltip content={'Toggle Second Y-Axis Line Chart'}>
										<Button
											id="toggleSecondYAxisLineChart"
											data-testid="toggleSecondYAxisLineChart"
											className={`${
												props.secondChartType === 'line'
													? 'btn-yaxis-selected btn-charttype'
													: 'btn-charttype'
											}`}
											onClick={(e) => {
												toggleItem('secondYaxisLine');
											}}
										>
											<FontAwesomeIcon icon={faChartLineUp} />
										</Button>
									</Tooltip>
								</div>
							</>
						)}
					<>
						<Tooltip
							content={'Horizontal Bar'}
							reference={barTooltip}
						/>
						<Tooltip
							content={'Vertical Bar'}
							reference={columnTooltip}
						/>
						<Tooltip
							content={'Scatter Plot'}
							reference={scatterTooltip}
						/>
						<Tooltip
							content={'Word Cloud'}
							reference={wordCloudTooltip}
						/>
						<Tooltip
							content={'Line'}
							reference={lineTooltip}
						/>
						<Tooltip
							content={'Pie'}
							reference={pieTooltip}
						/>
						<Tooltip
							content={'Donut'}
							reference={donutTooltip}
						/>
						<Tooltip
							content={'Bubble'}
							reference={packedBubbleTooltip}
						/>
						<Tooltip
							content={'Funnel'}
							reference={funnelTooltip}
						/>
						<Tooltip
							content={'Area'}
							reference={areaTooltip}
						/>
						<Tooltip
							content={'Lollipop'}
							reference={lollipopTooltip}
						/>
						<Tooltip
							content={'Pyramid'}
							reference={pyramidTooltip}
						/>
						<Tooltip
							content={'Odometer'}
							reference={odometerTooltip}
						/>
						<Tooltip
							content={'Column Range'}
							reference={columnRangeTooltip}
						/>
						<Tooltip
							content={'Heatmap'}
							reference={heatmapTooltip}
						/>
						<Tooltip
							content={'Stacked Column'}
							reference={stackedColumnTooltip}
						/>
						<Tooltip
							content={'Stacked Bar'}
							reference={stackedBarTooltip}
						/>
						<Tooltip
							content={'Clustered Line'}
							reference={clusteredlineTooltip}
						/>

						<Tooltip
							content={'Clustered Column'}
							reference={clusteredColumnTooltip}
						/>
						<Tooltip
							content={'Clustered Bar'}
							reference={clusteredBarTooltip}
						/>
						<Tooltip
							content={'Sankey Diagram'}
							reference={sankeyTooltip}
						/>
						<Tooltip
							content={'Dependency Wheel'}
							reference={depWheelTooltip}
						/>
					</>
				</>
			}
		>
			<>
				<Button
					onClick={() => toggleItem('type')}
					ref={chartTypeTooltipRef}
				>
					<FontAwesomeIcon
						id="chart-type"
						icon={faChartSimple}
					/>
				</Button>
				<Tooltip
					content={'Chart Type'}
					reference={chartTypeTooltipRef}
				/>
			</>
		</Popover>
	);

	const chartColorButton = (
		<Popover
			headerContent={'Colors'}
			bodyContent={
				<>
					{props.droppedFacts && props.droppedFacts.length > 1 && (
						<div>
							<Tooltip content="Primary Series Color">
								<>
									{' '}
									<Button
										className={
											currentChartSeriesNumber == 1
												? 'btn-chart-selected'
												: ''
										}
										onClick={() => {
											setCurrentChartSeriesNumber(1);
										}}
									>
										<FontAwesomeIcon icon={faChartSimple} />{' '}
										<FontAwesomeIcon icon={fa1} />
									</Button>
								</>
							</Tooltip>
							<Tooltip content="Secondary Series Color">
								<>
									{' '}
									<Button
										className={
											currentChartSeriesNumber == 2
												? 'btn-chart-selected'
												: ''
										}
										onClick={() => {
											setCurrentChartSeriesNumber(2);
										}}
									>
										<FontAwesomeIcon icon={faChartSimple} />{' '}
										<FontAwesomeIcon icon={fa2} />
									</Button>
								</>
							</Tooltip>
							<span className="spacer-sm"></span>
						</div>
					)}
					<Text component="h3">
						{' '}
						<div
							className="active-color-preview"
							style={{
								backgroundColor:
									currentChartSeriesNumber == 1
										? chartSeriesColor1
										: chartSeriesColor2,
							}}
						></div>{' '}
						<span className="active-color-label">
							Current Color is{' '}
							{currentChartSeriesNumber == 1
								? chartSeriesColor1.toUpperCase()
								: chartSeriesColor2.toUpperCase()}
						</span>
					</Text>
					<span className="spacer-xs"></span>
					<TwitterPicker
						color={backgroundColor}
						onChangeComplete={handleChangeComplete}
					/>
				</>
			}
		>
			<>
				<Button
					onClick={() => toggleItem('colors')}
					ref={colorsTooltipRef}
				>
					<FontAwesomeIcon icon={faPalette} />
				</Button>
				<Tooltip
					content={'Colors'}
					reference={colorsTooltipRef}
				/>
			</>
		</Popover>
	);

	const onLimitValueChange = (event: FormEvent<HTMLInputElement>): void => {
		const value = (event.target as HTMLInputElement).value;
		props.setChartSettings({
			...props.chartSettings,
			limitResults: +value,
		});
	};

	const chartSettingsButton = (
		<Popover
			headerContent={'Chart Settings'}
			bodyContent={
				<>
					<FormGroup label="Limit Results">
						<NumberInput
							value={props.chartSettings.limitResults}
							onMinus={() =>
								props.setChartSettings({
									...props.chartSettings,
									limitResults: Math.max(
										(props.chartSettings.limitResults ?? 1) - 1,
										0
									),
								})
							}
							onPlus={() =>
								props.setChartSettings({
									...props.chartSettings,
									limitResults: (props.chartSettings.limitResults ?? 1) + 1,
								})
							}
							onChange={onLimitValueChange}
						/>
					</FormGroup>
					<FormGroup>
						<Checkbox
							label="Show Data Value"
							isChecked={props.chartSettings.showDataValues}
							onChange={(c) =>
								props.setChartSettings({
									...props.chartSettings,
									showDataValues: c,
								})
							}
							id="showDataValue"
						/>
					</FormGroup>
					<FormGroup>
						<Checkbox
							label="Show as Percentage"
							isChecked={props.chartSettings.showPercentages}
							onChange={(c) =>
								props.setChartSettings({
									...props.chartSettings,
									showPercentages: c,
								})
							}
							id="showPercentage"
						/>
					</FormGroup>
					<FormGroup>
						<Checkbox
							label="Show Scrollbar"
							isChecked={props.chartSettings.showScrollbar}
							onChange={(c) =>
								props.setChartSettings({
									...props.chartSettings,
									showScrollbar: c,
								})
							}
							id="showScrollbar"
						/>
					</FormGroup>
					<FormGroup>
						<Checkbox
							label="Hide Y-Axis Scale"
							isChecked={props.chartSettings.hideYAxis}
							onChange={(c) =>
								props.setChartSettings({
									...props.chartSettings,
									hideYAxis: c,
								})
							}
							id="hideValueScale"
						/>
					</FormGroup>
					<FormGroup>
						<Checkbox
							label="Show Chart Legend"
							isChecked={!props.chartSettings.hideLegend}
							onChange={(c) => {
								props.setChartSettings({
									...props.chartSettings,
									hideLegend: !c,
								});
							}}
							id="showChartLegend"
						/>
					</FormGroup>
				</>
			}
		>
			<>
				<Button
					onClick={() => toggleItem('settings')}
					ref={chartSettingsTooltipRef}
				>
					<FontAwesomeIcon icon={faGear} />
				</Button>
				<Tooltip
					content={'Chart Settings'}
					reference={chartSettingsTooltipRef}
				/>
			</>
		</Popover>
	);

	// endregion popovers

	const noSeriesDataHtml = (
		<EmptyResults
			emptyStateBody="No results match the configured report criteria. Change the Facts and Fields to retrieve report data"
			iconName={faInfoCircle}
			backComponent={<></>}
		/>
	);

	const recordLimitWarnHtml = (
		<EmptyResults
			emptyStateBody="More than 500 rows are present in the dataset. Apply filters to narrow your selection and reduce the number of rows, or consider creating a report that runs via the job queue to return all records."
			heading="Data Limit Warning"
			iconName={faWarning}
			backComponent={<></>}
		/>
	);

	return (
		<>
			<div
				className="zi-chart-container"
				style={{ width: '18.75rem' }}
			>
				{props.isConfigMode && (
					<div className="chart-controls">
						{props.drillLevel && props.drillLevel - 1 > 0 && (
							<Button
								onClick={() => toggleItem('drillOut')}
								ref={chartTypeTooltipRef}
							>
								<FontAwesomeIcon
									id="chart-type"
									icon={faChevronCircleUp}
								/>{' '}
								Drill Out
							</Button>
						)}
						<>{chartTypeButton}</>
						<>
							<Button
								id="toggleSidebarBtn"
								onClick={() => toggleItem('drill')}
								ref={drilldownTooltipRef}
							>
								<FontAwesomeIcon icon={faChevronsDown} />
							</Button>
							<Tooltip
								content={'Drilldown'}
								reference={drilldownTooltipRef}
							/>
						</>
						{chartColorButton}
						<></>
						<>{chartSettingsButton}</>
						<>
							<Button
								onClick={() => toggleItem('insights')}
								ref={insightsTooltipRef}
								isDisabled={!chartId}
							>
								<FontAwesomeIcon icon={faLightbulb} />
							</Button>
							<Tooltip
								content={'Insights'}
								reference={insightsTooltipRef}
							/>
						</>
					</div>
				)}
				{props.isDataRetrievalLoading && <Loader />}
				{!props.isDataRetrievalLoading &&
					hasLoadedChartOptions &&
					props.showLimitWarning == false && (
						<>{xAxisArray && xAxisArray.length > 0 ? chartRender : noSeriesDataHtml}</>
					)}

				{props.showLimitWarning && recordLimitWarnHtml}
			</div>
		</>
	);
};

export default ZiChart;
