import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { useMount } from 'react-use';
import { Role, TRole } from '../../api/security/Role';
import { Permission as ApiPermission, TPermission } from '../../api/security/Permission';
import { Column } from '@zeroedin-tech/zi-common-ui/lib/';
import FilterTableLayout from '../../layout/FilterTableLayout';
import ZiTable, { Action } from '../../components/table/ZiTable';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalBoxFooter, ModalVariant } from '@patternfly/react-core';
import SchnurForm, {
	Field,
	IDualListOption,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import { TUser, User } from '../../api/security/User';
export default function Roles(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [tableData, setTableData] = useState<TRole[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isRoleDeleteModalOpen, setIsRoleDeleteModalOpen] = useState<boolean>(false);
	const [activeRole, setActiveRole] = useState<TRole>(Role.Default() as TRole);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [users, setUsers] = useState<TUser[]>([]);
	const [permissions, setPermissions] = useState<TPermission[]>([]);

	const selectedColumns: Column<TRole>[] = [
		{
			title: 'Name',
			columnName: 'name',
		},
		{
			title: 'Description',
			columnName: 'description',
		},
		{
			title: 'Permissions',
			columnName: 'permissions',
			customAccessor: (item) => `${item.permissions.length} permissions`,
			sortable: true,
		},
	];

	const actions: Action<TRole>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveRole(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditRole,
		},
		{
			name: 'Delete',
			callback: (item) => {
				const hasUser = users.find((u) => u.role == item.id);

				if (!hasUser) {
					setActiveRole(item);
					setIsRoleDeleteModalOpen(true);
				} else {
					addToast('Cannot delete a User Role with assigned users.', AlertVariant.danger);
				}
			},
			permission: Permission.DeleteUnitType,
		},
	];

	const formProperties: Field<TRole>[] = [
		{
			title: 'Role Name',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The name of the Role.',
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
				placeholder: 'Key Measure Description',
				helpText: 'The description of the Key Measure to display in the UI.',
			},
			maxLength: 255,
		},
		{
			title: 'Permissions',
			columnName: 'permissions',
			uiSchema: {
				type: UIType.DUAL_LIST,
				options: permissions.map(
					(permission) =>
						({ key: permission.id, value: permission.name } as ISelectOption)
				),
				onSelect: (value: IDualListOption[]) => {
					return [...value.map((option) => Number(option.key))];
				},
				selected: activeRole.permissions
					.map((groupPermission) => {
						if (typeof groupPermission === 'object') {
							return {
								key: groupPermission.id,
								value: groupPermission.name,
							} as ISelectOption;
						} else if (typeof groupPermission === 'number') {
							const foundPermission = permissions.find(
								(permission) => permission.id === groupPermission
							);
							return {
								key: foundPermission?.id,
								value: foundPermission?.name,
							} as ISelectOption;
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[],
			},
			required: true,
		},
	];

	useMount(() => {
		Role.GetAll(['permissions'])
			.then((roles) => {
				setTableData(roles);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to fetch Roles. Please try again later.',
					AlertVariant.danger
				);
			});

		User.GetAll()
			.then((user) => {
				setUsers(user);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to this users user. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		ApiPermission.GetAll()
			.then((permission) => {
				setPermissions(permission);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to this permissions permission. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Roles"
					pageDescription="Manage Role users and data."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const addButton = (
		<PermissionButton
			data-testid={'role-create'}
			variant={'primary'}
			permission={Permission.CreateRole}
			onClick={() => {
				setActiveRole(Role.Default() as TRole);
				setIsModalOpen(true);
			}}
		>
			New Role
		</PermissionButton>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleDeleteModalClose = () => {
		setIsRoleDeleteModalOpen(false);
	};

	const handleDelete = () => {
		setTableLoading(true);

		Role.Delete(activeRole.id)
			.then(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				setTableData((prev) => prev.filter((user) => user.id !== activeRole.id));
				addToast('User Role deleted successfully.', AlertVariant.success);
			})
			.catch(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				addToast(
					'An error occurred while trying to delete User Role. Please try again later.',
					AlertVariant.danger
				);
			});
	};

	const handleSuccess = (value: TRole) => {
		const index = tableData.findIndex((row) => row.id === value.id);

		if (index >= 0) {
			setTableData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setTableData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const roleTable = (
		<ZiTable<TRole>
			ariaLabel={'User Roles'}
			columns={selectedColumns}
			data={tableData}
			caption="User Roles"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Role Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TRole>
					title={'User Role Management'}
					fields={formProperties}
					initialSubject={activeRole}
					isLoading={isFormLoading}
					onSubmit={(role) => {
						setIsFormLoading(true);

						if (role.id) {
							Role.Update(role, ['permissions'])
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the Role. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							Role.New(role, ['permissions'])
								.then((newRole) => {
									handleSuccess(newRole);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the Role. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<Modal
				title="User Role Delete Confirmation"
				variant="small"
				isOpen={isRoleDeleteModalOpen}
				onClose={handleDeleteModalClose}
				className="delete-modal"
			>
				<hr />
				<br />
				<div className="text-center">
					<h3>Are you sure you want to delete {activeRole.name}?</h3>
				</div>
				<br />
				<hr />
				<ModalBoxFooter className="pull-right">
					<Button
						variant="secondary"
						onClick={handleDeleteModalClose}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleDelete}
					>
						Delete
					</Button>
				</ModalBoxFooter>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={roleTable}
					layoutActions={addButton}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
