import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { Dimension, DimensionTypeOptions, TDimension } from '../../api/analytics/Dimension';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import SchnurForm, {
	EventType,
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import SchemaUpdateButton from '../../components/button/SchemaUpdateButton';
import { useIsGranted } from '../../components/user/ApplicationProvider';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';

export default function Dimensions(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<Dimension[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeDimension, setActiveDimension] = useState<TDimension>(
		Dimension.DefaultNewDimension() as TDimension
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const isGranted = useIsGranted();

	const selectedColumns: Column<TDimension>[] = [
		{
			title: 'Name',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'Alias',
			columnName: 'code',
			sortable: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			sortable: true,
		},
		{
			title: 'Type',
			columnName: 'type',
			sortable: true,
			customAccessor: (item) =>
				String(DimensionTypeOptions.find((option) => option.key === item.type)?.value),
		},
	];

	const formProperties: Field<TDimension>[] = [
		{
			title: 'Alias',
			columnName: 'code',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'dimension_alias',
				helpText: 'The alias of the key measure to use in SQL statements.',
			},
			required: true,
			validate: (eventType, value) => {
				if (eventType !== EventType.BLUR && eventType !== EventType.CHANGE) {
					return true;
				}

				if (value.includes(' ')) {
					return 'An alias must not contain a space!';
				}

				return true;
			},
		},
		{
			title: 'Title',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The title that will display for this dimension.',
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
				helpText: 'The description that will display for this dimension.',
			},
			maxLength: 255,
		},
		{
			title: 'Type',
			columnName: 'type',
			uiSchema: {
				type: UIType.SELECT,
				options: DimensionTypeOptions,
				onSelect: (selected: ISelectOption) => {
					return selected.key;
				},
				initialSelection: activeDimension.type !== '' ? activeDimension.type : null,
			},
			required: true,
		},
		{
			title: 'Is Unique',
			columnName: 'is_unique',
			uiSchema: {
				type: UIType.BOOLEAN,
				helpText: 'If checked, this dimension will be unique.',
			},
		},
		{
			title: 'Allow Filtering',
			columnName: 'allow_filtering',
			uiSchema: {
				type: UIType.BOOLEAN,
				helpText: 'If checked, this dimension will be allowed to be filtered.',
			},
		},
	];

	const actions: Action<Dimension>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveDimension(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditDimension,
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);
				Dimension.DeleteDimension(item.id)
					.then(() => {
						setTableLoading(false);
						setData((prev) => prev.filter((dimension) => dimension.id !== item.id));
						addToast('Dimension deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete Dimension. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeleteDimension,
		},
	];

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Dimensions"
					pageDescription="Manage your Dimensions."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		Dimension.GetAll()
			.then((dimensions) => {
				setData(dimensions);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load Dimensions. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: Dimension) => {
		const index = data.findIndex((dimension) => dimension.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const addButton = (
		<PermissionButton
			data-testid={'zone-provision'}
			variant={'primary'}
			permission={Permission.CreateDimension}
			onClick={() => {
				setActiveDimension(Dimension.DefaultNewDimension() as TDimension);
				setIsModalOpen(true);
			}}
		>
			New Dimension
		</PermissionButton>
	);

	const dimensionTable = (
		<ZiTable<Dimension>
			ariaLabel={'Dimension table'}
			columns={selectedColumns}
			data={data}
			caption="Dimensions"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Dimension Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TDimension>
					title={'Dimension Management'}
					fields={formProperties}
					initialSubject={activeDimension}
					isLoading={isFormLoading}
					onSubmit={(dimension) => {
						setIsFormLoading(true);

						if (dimension.id) {
							Dimension.Update(dimension)
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save Dimension. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							Dimension.NewDimension(dimension)
								.then((newDim) => {
									handleSuccess(newDim);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save Dimension. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={dimensionTable}
					layoutActions={[addButton, <SchemaUpdateButton />]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
