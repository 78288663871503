import React, { useCallback, useEffect, useRef, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import Dumbbell from 'highcharts/modules/dumbbell';
import Lollipop from 'highcharts/modules/lollipop';
import Funnel from 'highcharts/modules/funnel';
import WordCloud from 'highcharts/modules/wordcloud';
import Heatmap from 'highcharts/modules/heatmap';
import Sankey from 'highcharts/modules/sankey';
import DependencyWheel from 'highcharts/modules/dependency-wheel';
import SolidGauge from 'highcharts/modules/solid-gauge';
import { ZiChartOptions } from '../types/charts/chart-options';

HighchartsMore(Highcharts);
WordCloud(Highcharts);
Funnel(Highcharts);
Dumbbell(Highcharts);
Lollipop(Highcharts);
Heatmap(Highcharts);
Sankey(Highcharts);
DependencyWheel(Highcharts);
SolidGauge(Highcharts);

type RefObjectForHighchartsReact = {
	/**
	 * Chart reference
	 */
	chart: Highcharts.Chart;
	/**
	 * React reference
	 */
	container: React.RefObject<HTMLDivElement>;
};

export const useHighChart = (): {
	options: ZiChartOptions;
	setOptions: (options: ZiChartOptions) => void;
	chartRender: JSX.Element;
} => {
	const [chartOptions, setChartOptions] = useState<ZiChartOptions>({});
	const [immutable, setImmutable] = useState<boolean>(false);

	const chartRef = useRef<RefObjectForHighchartsReact>(null);
	useEffect(() => {
		if (
			chartRef.current?.chart.chartHeight != chartOptions.height ||
			chartRef.current?.chart.chartWidth != chartOptions.width
		) {
			chartRef.current?.chart.setSize(
				(chartOptions.width as number) ?? 0,
				(chartOptions.height as number) ?? 0
			);
		}
	}, [chartOptions.height, chartOptions.width]);

	const updateOptions = useCallback((options: ZiChartOptions) => {
		setChartOptions((previousOptions) => {
			// This is to force chart reload on chart type change
			// Seems to be a bug in some cases when updating chart type from bar to column where it does not update correctly
			if (previousOptions.chart?.type !== options.chart?.type) {
				setImmutable(() => true);
			} else {
				setImmutable(() => false);
			}
			return options;
		});
	}, []);

	// Highcharts font family
	if (chartOptions.chart) {
		chartOptions.chart.style = {
			fontFamily: "'Inter', 'RedHatText', 'Overpass', overpass, helvetica",
		};
	}

	return {
		options: chartOptions,
		setOptions: updateOptions,
		chartRender: (
			<HighchartsReact
				ref={chartRef}
				highcharts={Highcharts}
				options={chartOptions}
				containerProps={{ style: { height: '100%' } }}
				immutable={immutable}
			/>
		),
	};
};
