import { AlertVariant, Modal, ModalVariant, Spinner } from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import {
	AlertDefinition,
	TAlertDefinition,
	TNewAlertDefinition,
} from '../../api/alerts/AlertDefinition';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { useMount } from 'react-use';
import { TKeyMeasure } from '../../api/analytics/KeyMeasure';
import { PeriodTypesEnum } from '../../enums/period-types-enum';
import { AlertConditionsEnum } from '../../enums/alet-conditions.enum';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { useLocation, useParams } from 'react-router-dom';
import { Chart } from '../../api/charts/Chart';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	droppedFacts: DraggableMenuItemData[];
	isLoadingForEdit?: boolean;
	setIsLoadingForEdit?: React.Dispatch<React.SetStateAction<boolean>>;
	existingAlert?: TAlertDefinition;
	filteredKeyMeasures?: TKeyMeasure[];
	refetchAlertDefinitions?: () => void;
};

function AlertDefinitionModal(props: Props) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { addToast } = useToast();
	const [activeAlert, setActiveAlert] = useState<TAlertDefinition>(
		AlertDefinition.Default() as TAlertDefinition
	);

	const [initialFormProperties, setInitialFormProperties] = useState<
		Field<TNewAlertDefinition>[]
	>([]);
	const [currentFormProperties, setCurrentFormProperties] = useState<
		Field<TNewAlertDefinition>[]
	>([]);
	const [excludedKeymeasureFactId, setExcludedKeymeasureFactId] = useState<number>();
	const { chartId } = useParams();
	const [dataframeId, setDataframeId] = useState<number>();
	const pathname = useLocation().pathname;
	const isHomePage = pathname == '/getting-started' || pathname == '/';

	useMount(() => {
		const id = chartId ? +chartId : 0;

		if (id > 0) {
			void Chart.GetOne(id).then((response) => {
				setDataframeId(response.dataframe);
			});
		}

		if (props.existingAlert) {
			setActiveAlert(props.existingAlert);
		}
	});

	useEffect(() => {
		if (props.isOpen) {
			setTimeout(() => {
				const element = document.getElementsByClassName('pf-c-form__group')[
					isHomePage ? 5 : 10
				] as HTMLElement;
				if (element) {
					element.style.display = 'none';
				}

				if (props.existingAlert) {
					setActiveAlert(props.existingAlert);
					if (props.existingAlert.comparisonKeyMeasureFact) {
						const option: ISelectOption = {
							key: 'comparison',
							value: 'Comparison',
						};
						updateComparisonType(option);
					} else {
						const option: ISelectOption = {
							key: 'value',
							value: 'Value',
							selectedByDefault: true,
						};
						updateComparisonType(option);
					}
				}
			}, 2000);

			const elements = document.querySelectorAll('.pf-c-form__label-text');

			// Find the element with the inner text 'Comparison Type'
			const targetElement = Array.from(elements).find(
				(element) => (element as HTMLElement).outerText == 'Comparison Type'
			);

			if (targetElement) {
				targetElement.outerHTML = `<div class="pf-c-form__group-label">
						<label class="pf-c-form__label" for="key-measure-alerts-comparison_type">
							<span class="pf-c-form__label-text">Comparison Type</span>
							<span class="pf-c-form__label-required" aria-hidden="true"> *</span>
						</label> 
					</div>`;
			}
		} else {
			setExcludedKeymeasureFactId(undefined);
		}
	}, [props.isOpen]);

	useEffect(() => {
		const formProperties: Field<TNewAlertDefinition>[] = [
			{
				title: 'Alert Name',
				columnName: 'name',
				uiSchema: {
					type: UIType.TEXT,
				},
				required: true,
			},
			{
				title: 'Key Measure',
				columnName: 'keyMeasureFact',
				uiSchema: {
					type: UIType.KEYMEASURE_FACT_SELECT,
					kmOptions: props.filteredKeyMeasures,
					onSelect: (value: ISelectOption) => returnKmfIdForKmf(value.key.toString()),
					initialSelection: props.existingAlert?.keyMeasureFact,
				},
				required: true,
			},
			{
				title: 'Operator',
				columnName: 'condition',
				uiSchema: {
					type: UIType.SELECT,
					options: Object.values(AlertConditionsEnum).map(
						(condition) => ({ key: condition, value: condition } as ISelectOption)
					),
					onSelect: (value: ISelectOption) => value.key,
				},
				required: true,
			},
			{
				title: 'Comparison Type',
				columnName: 'comparison_type',
				uiSchema: {
					type: UIType.RADIO,
					options: [
						{
							key: 'value',
							value: 'Value',
							selectedByDefault: !props.existingAlert?.comparisonKeyMeasureFact
								? true
								: false,
						},
						{
							key: 'comparison',
							value: 'Comparison',
							selectedByDefault: props.existingAlert?.comparisonKeyMeasureFact
								? true
								: false,
						},
					] as ISelectOption[],
					onSelect: (selectedOption: ISelectOption) =>
						updateComparisonType(selectedOption),
				},
			},
			{
				title: 'Value',
				columnName: 'value',
				uiSchema: {
					type: UIType.TEXT,
				},
			},
			{
				title: 'Key Measure Comparison',
				columnName: 'comparisonKeyMeasureFact',
				uiSchema: {
					type: UIType.KEYMEASURE_FACT_SELECT,
					kmOptions: excludedKeymeasureFactId
						? props.filteredKeyMeasures?.filter((x) =>
								x.keyMeasureFacts.some((y) => y.id != excludedKeymeasureFactId)
						  )
						: props.filteredKeyMeasures,
					onSelect: (value: ISelectOption) => returnKmfIdForKmf(value.key.toString()),
					initialSelection: props.existingAlert?.comparisonKeyMeasureFact,
				},
			},
			{
				title: 'Triggered Color',
				columnName: 'color',
				uiSchema: {
					type: UIType.COLOR_PICKER,
				},
			},
			{
				title: 'Period',
				columnName: 'period',
				uiSchema: {
					type: UIType.SELECT,
					options: Object.values(PeriodTypesEnum).map(
						(period) =>
							({
								key: returnPeriodIdForPeriod(period),
								value: period,
							} as ISelectOption)
					),
					onSelect: (value: ISelectOption) => value.key,
				},
				required: true,
			},
			{
				title: 'Monitoring Window (Days)',
				columnName: 'monitorWindow',
				uiSchema: {
					type: UIType.NUMBER,
				},
			},
			{
				title: 'Action(s) to be Taken',
				columnName: 'actions',
				uiSchema: {
					type: UIType.TEXTAREA,
				},
			},
			{
				title: 'Notify Via Email',
				columnName: 'notifyByEmail',
				uiSchema: {
					type: UIType.CHECKBOX,
				},
			},
			{
				title: 'Last Notified Date',
				columnName: 'email_notification_date',
				uiSchema: {
					type: UIType.DATE,
				},
				disabled: true,
			},
		];

		setCurrentFormProperties(formProperties);
	}, [props.filteredKeyMeasures]);

	const droppedFactsKeyValue = props.droppedFacts.map((x) => ({
		id: x.data?.id,
		value: x.data?.title,
	}));

	const returnPeriodIdForPeriod = (period: string) => {
		switch (period) {
			case 'Year':
				return 1;
			case 'Quarter':
				return 2;
			case 'Month':
				return 3;
			case 'Week':
				return 4;
			case 'Day':
				return 5;
		}
	};

	const returnKmfIdForKmf = (kmfName: string) => {
		const idToReturn = droppedFactsKeyValue.find((x) => x.value == kmfName)?.id;

		if (idToReturn) setExcludedKeymeasureFactId(idToReturn);

		return idToReturn;
	};

	const formProperties: Field<TNewAlertDefinition>[] = [
		{
			title: 'Alert Name',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Key Measure',
			columnName: 'keyMeasureFact',
			uiSchema: {
				type: UIType.KEYMEASURE_FACT_SELECT,
				kmOptions: props.filteredKeyMeasures,
				onSelect: (value: ISelectOption) => returnKmfIdForKmf(value.key.toString()),
				initialSelection: props.existingAlert?.keyMeasureFact,
			},
			required: true,
		},
		{
			title: 'Operator',
			columnName: 'condition',
			uiSchema: {
				type: UIType.SELECT,
				options: Object.values(AlertConditionsEnum).map(
					(condition) => ({ key: condition, value: condition } as ISelectOption)
				),
				onSelect: (value: ISelectOption) => value.key,
			},
			required: true,
		},
		{
			title: 'Comparison Type',
			columnName: 'comparison_type',
			uiSchema: {
				type: UIType.RADIO,
				options: [
					{
						key: 'value',
						value: 'Value',
						selectedByDefault: !props.existingAlert?.comparisonKeyMeasureFact
							? true
							: false,
					},
					{
						key: 'comparison',
						value: 'Comparison',
						selectedByDefault: props.existingAlert?.comparisonKeyMeasureFact
							? true
							: false,
					},
				] as ISelectOption[],
				onSelect: (selectedOption: ISelectOption) => updateComparisonType(selectedOption),
			},
		},
		{
			title: 'Value',
			columnName: 'value',
			uiSchema: {
				type: UIType.TEXT,
			},
		},
		{
			title: 'Key Measure Comparison',
			columnName: 'comparisonKeyMeasureFact',
			uiSchema: {
				type: UIType.KEYMEASURE_FACT_SELECT,
				kmOptions: excludedKeymeasureFactId
					? props.filteredKeyMeasures?.filter((x) =>
							x.keyMeasureFacts.some((y) => y.id != excludedKeymeasureFactId)
					  )
					: props.filteredKeyMeasures,
				onSelect: (value: ISelectOption) => returnKmfIdForKmf(value.key.toString()),
				initialSelection: props.existingAlert?.comparisonKeyMeasureFact,
			},
		},
		{
			title: 'Triggered Color',
			columnName: 'color',
			uiSchema: {
				type: UIType.COLOR_PICKER,
			},
		},
		{
			title: 'Period',
			columnName: 'period',
			uiSchema: {
				type: UIType.SELECT,
				options: Object.values(PeriodTypesEnum).map(
					(period) =>
						({ key: returnPeriodIdForPeriod(period), value: period } as ISelectOption)
				),
				onSelect: (value: ISelectOption) => value.key,
			},
			required: true,
		},
		{
			title: 'Monitoring Window (Days)',
			columnName: 'monitorWindow',
			uiSchema: {
				type: UIType.NUMBER,
			},
		},
		{
			title: 'Action(s) to be Taken',
			columnName: 'actions',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
		},
		{
			title: 'Notify Via Email',
			columnName: 'notifyByEmail',
			uiSchema: {
				type: UIType.CHECKBOX,
			},
		},
		{
			title: 'Last Notified Date',
			columnName: 'email_notification_date',
			uiSchema: {
				type: UIType.DATE,
			},
			disabled: true,
		},
	];

	useEffect(() => {
		setInitialFormProperties(formProperties);
	}, []);

	useEffect(() => {
		setInitialFormProperties(formProperties);
	}, [props.filteredKeyMeasures, excludedKeymeasureFactId]);

	//eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	function getPropertyValue(obj: Record<string, any>, propName: string) {
		//eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return obj[propName];
	}

	const updateComparisonType = (value: ISelectOption) => {
		const firstIdx = isHomePage ? 3 : 10;
		const secondIdx = isHomePage ? 4 : 9;
		//eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
		const actualParentSelectedOption = getPropertyValue(value, 'key');

		//eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
		const actualSelectedOption =
			//eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
			getPropertyValue(actualParentSelectedOption, 'key') ?? actualParentSelectedOption;

		//as long as the current form properties list does not change this will work,
		//if the formProperties list gets updated or new items gets added this code needs to get updated as well
		//to reflect the correct indexes for comparison dropdown & comparison value fields
		if (actualSelectedOption == 'comparison') {
			let element = document.getElementsByClassName('pf-c-form__group')[
				firstIdx
			] as HTMLElement;
			if (element) {
				element.style.display = 'initial';
			}

			element = document.getElementsByClassName('pf-c-form__group')[
				isHomePage ? firstIdx + 2 : firstIdx
			] as HTMLElement;
			if (element) {
				element.style.display = 'initial';
			}

			const element2 = document.getElementsByClassName('pf-c-form__group')[
				secondIdx
			] as HTMLElement;
			if (element2) {
				element2.style.display = 'none';
			}
		} else {
			const element = document.getElementsByClassName('pf-c-form__group')[
				isHomePage ? firstIdx + 2 : firstIdx
			] as HTMLElement;
			if (element) {
				element.style.display = 'none';
			}

			const element2 = document.getElementsByClassName('pf-c-form__group')[
				isHomePage ? 4 : secondIdx
			] as HTMLElement;
			if (element2) {
				element2.style.display = 'initial';
			}
		}

		return value.key;
	};

	const Save = (alert: AlertDefinition) => {
		setIsLoading(true);

		alert.chart = chartId ? +chartId : 0;
		alert.dataframe = dataframeId ?? 0;
		alert.effectiveBeginDate = 0;
		alert.effectiveEndDate = 0;
		alert.emailReminderDays = 0;

		if (!alert.notifyByEmail) {
			alert.notifyByEmail = false;
		}

		if (!alert.monitorWindow) {
			alert.monitorWindow = 0;
		}

		if (alert.id) {
			alert.dataframe = activeAlert.dataframe;
			alert.chart = activeAlert.chart;

			AlertDefinition.Update(alert)
				.then((updatedResponse) => {
					addToast('Alert Succesfully Updated', AlertVariant.success);
					props.onClose();
					setActiveAlert(updatedResponse);

					if (isHomePage) {
						props.refetchAlertDefinitions && props.refetchAlertDefinitions();
					}
				})
				.catch(() => {
					addToast(
						'An error occurred while trying to save the User. Please try again later.',
						AlertVariant.danger
					);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			AlertDefinition.New(alert)
				.then((_reponse) => {
					addToast('Alert Succesfully Created', AlertVariant.success);
					props.onClose();
				})
				.catch(() => {
					addToast(
						'An error occurred while trying to save the User. Please try again later.',
						AlertVariant.danger
					);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	return (
		<div>
			<Modal
				title="Key Measure Alerts"
				variant={ModalVariant.small}
				isOpen={props.isOpen}
				onClose={props.onClose}
			>
				<div>
					{(isLoading || props.isLoadingForEdit) && (
						<div className="spinner-container">
							<Spinner size={'lg'} />
						</div>
					)}
					{!(isLoading || props.isLoadingForEdit) && (
						<>
							<SchnurForm<TAlertDefinition>
								title={'Key Measure Alerts'}
								fields={
									currentFormProperties && currentFormProperties.length > 0
										? currentFormProperties
										: initialFormProperties
								}
								initialSubject={activeAlert}
								isLoading={isLoading}
								onSubmit={(alert) => {
									Save(alert);
								}}
							/>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
}

export default AlertDefinitionModal;
