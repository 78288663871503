import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormGroup, TreeViewDataItem } from '@patternfly/react-core';
import './KeyMeasureView.scss';
import { useMount } from 'react-use';
import { TKeyMeasure } from '../../../../api/analytics/KeyMeasure';
import { TDimension } from '../../../../api/analytics/Dimension';
import { TKeyMeasureFact } from '../../../../api/analytics/KeyMeasureFact';
import { TDimensionAttribute } from '../../../../api/analytics/DimensionAttribute';
import { BuildTreeViewItem } from '../../../../helpers/tree-view.helper';
import SearchableTreeViewSelect from '../../../form/Select/SearchableTreeViewSelect';
import { defaultDateRangeDim } from '../../../../constants/default-date-range-dim.constant';
import { OptionsBuilderItemTypes } from '../../../../types/dataframes/options-builder-item-types';
import { useApplication } from '../../../user/ApplicationProvider';
import _ from 'lodash';

export type KeyMeasureViewProps = {
	onSelectMeasure: Dispatch<SetStateAction<TKeyMeasure | undefined>>;
	selectedMeasure?: TKeyMeasure;
	onSelectFact: Dispatch<SetStateAction<TKeyMeasureFact | undefined>>;
	selectedFact?: TKeyMeasureFact;
	onSelectDimension: Dispatch<SetStateAction<TDimension | undefined>>;
	selectedDimension?: TDimension;
	onSelectDimensionAttribute: Dispatch<SetStateAction<TDimensionAttribute | undefined>>;
	selectedDimensionAttribute?: TDimensionAttribute;
	setCreateDisabled: Dispatch<SetStateAction<boolean>>;
	hideDimensionSelection: boolean;
};

function KeyMeasureView(props: KeyMeasureViewProps) {
	const { measures, dimensions } = useApplication();
	const [series, setSeries] = useState<TDimension[]>([]);

	useMount(() => {
		props.onSelectDimensionAttribute(undefined);
		props.onSelectMeasure(undefined);
		props.onSelectFact(undefined);
		props.onSelectDimension(undefined);
	});

	const onFocusKeyMeasures = () => {
		const element = document.getElementById('km-toggle');
		element?.focus();
	};

	const onFocusSeries = () => {
		const element = document.getElementById('series-toggle');
		element?.focus();
	};

	const onSelectKeyMeasure = (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		props.onSelectDimensionAttribute(undefined);
		props.onSelectDimension(undefined);

		if (item.id && item.id?.includes('__') && item.id !== '-1') {
			const [measureId, factId] = item.id.split('__');

			const measure = measures.find((_) => _.id === +measureId);
			props.onSelectMeasure(measure);
			props.onSelectFact(measure?.keyMeasureFacts.find((_) => _.id === +factId));
			onFocusKeyMeasures();

			if (props.hideDimensionSelection) {
				props.setCreateDisabled(false);
			}

			const dims = measure?.dimensions ?? [];
			const dimIds = (dims as TDimension[]).map((_) => _.id);
			const clonedDims = _.cloneDeep(dimensions);

			const dropdownSeries = clonedDims.filter((_) => dimIds.includes(_.id));

			dropdownSeries.map((serie: TDimension) => {
				const newDimAttribute: TDimensionAttribute = {
					code: serie.code,
					creator: serie.creator,
					created_at: serie.created_at,
					name: serie.name,
					id: serie.id,
					show_on_summary: serie.show_on_summary,
					type: 'Dimension',
					dimension: serie,
					disabled: false,
					display_attribute: true,
					effective_dated: false,
					sequence: 0,
				};
				const existingSeries = dimensions.find((x) => x.id === serie.id);
				const existingDimAttr = existingSeries?.dimensionAttributes.find(
					(x) => x.id == serie.id
				);

				serie.dimensionAttributes.forEach((dimAttr) => {
					dimAttr.type = OptionsBuilderItemTypes.DimensionAttribute;
				});

				if (!existingDimAttr) serie.dimensionAttributes.push(newDimAttribute);
			});

			setSeries([...[defaultDateRangeDim], ...dropdownSeries]);
		} else if (item.id !== undefined && item.id !== '-1') {
			const id = parseInt(item.id) ?? 0;
			const measure = measures.find((_) => _.id === id);
			props.onSelectMeasure(measure);
			props.onSelectFact(measure?.keyMeasureFacts[0]);
			onFocusKeyMeasures();

			const dims = measure?.dimensions ?? [];
			const dimIds = (dims as TDimension[]).map((_) => _.id);

			const clonedDims = _.cloneDeep(dimensions);
			const dropdownSeries = clonedDims.filter((_) => dimIds.includes(_.id));

			dropdownSeries.map((serie) => {
				const newDimAttribute: TDimensionAttribute = {
					code: serie.code,
					creator: serie.creator,
					created_at: serie.created_at,
					name: serie.name,
					id: serie.id,
					show_on_summary: serie.show_on_summary,
					type: 'Dimension',
					dimension: serie,
					disabled: false,
					display_attribute: true,
					effective_dated: false,
					sequence: 0,
				};

				const existingSeries = series.find((x) => x.id === serie.id);
				const existingDimAttr = existingSeries?.dimensionAttributes.find(
					(x) => x.id == serie.id
				);

				serie.dimensionAttributes.forEach((dimAttr) => {
					dimAttr.type = OptionsBuilderItemTypes.DimensionAttribute;
				});

				if (!existingDimAttr) serie.dimensionAttributes.push(newDimAttribute);
			});
			setSeries([...[defaultDateRangeDim], ...dropdownSeries]);
		} else if (item.id && item.id === '-1') {
			props.onSelectFact(undefined);
		}
	};

	const onSelectDimension = (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	) => {
		if (item.id === '-1') return;

		if (item.id?.includes('__')) {
			const [ids, type] = item.id.split('-');
			const [dimensionId, attributeId] = ids.split('__');

			const dimension = series.find((_) => _.id.toString() === dimensionId);

			if (dimension) {
				if (type === OptionsBuilderItemTypes.Dimension) {
					props.onSelectDimension(dimension);
				} else if (type === OptionsBuilderItemTypes.DimensionAttribute) {
					const attr = dimension.dimensionAttributes.find(
						(_) => _.id.toString() === attributeId
					);
					props.onSelectDimensionAttribute(attr);
				}
			}
		} else {
			const dimensionId = item.id ?? '0';
			const dimension = series.find((_) => _.id.toString() === dimensionId);
			props.onSelectDimension(dimension);
			const lastDimAttr =
				dimension?.dimensionAttributes[dimension?.dimensionAttributes.length - 1];
			props.onSelectDimensionAttribute(lastDimAttr);
		}

		onFocusSeries();
		props.setCreateDisabled(false);
	};

	const emptyTree = [
		BuildTreeViewItem({ id: -1, name: 'No Dimensions or Attributes were found' }, []),
	];

	return (
		<div
			className="options-container"
			data-testid="key-measure-view"
		>
			<div className="form-group-col">
				<FormGroup
					label="Key Measure"
					isRequired
				>
					<SearchableTreeViewSelect
						data-testid="key-measure-tree"
						className="key-measure-tree no-innerscroll"
						data={
							measures.length > 0
								? measures.map((km) => BuildTreeViewItem(km, km.keyMeasureFacts))
								: emptyTree
						}
						placeHolderText={props.selectedFact?.name ?? 'Select a Fact'}
						onSelect={onSelectKeyMeasure}
						treeItemsExpanded={true}
					/>
				</FormGroup>
			</div>
			{props.selectedMeasure && !props.hideDimensionSelection && (
				<>
					<span>By</span>
					<FormGroup label="Series">
						<div className="form-group-col">
							<SearchableTreeViewSelect
								className="selectSeries no-innerscroll"
								data={
									series.length > 0
										? series.map((s) =>
												BuildTreeViewItem(s, s.dimensionAttributes)
										  )
										: emptyTree
								}
								placeHolderText={
									props.selectedDimensionAttribute?.name ??
									props.selectedDimension?.name ??
									'Select a Series or Series Attribute'
								}
								onSelect={onSelectDimension}
								treeItemsExpanded={true}
							/>
						</div>
					</FormGroup>
				</>
			)}
		</div>
	);
}

export default KeyMeasureView;
