import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

interface Props {
	isFormError: boolean;
}

function RequiredFormLabel(props: Props) {
	return (
		<div>
			{props.isFormError && (
				<div className="display-inline">
					<span className="pf-c-form__helper-text-icon">
						<FontAwesomeIcon icon={faInfoCircle} />
					</span>
					<div
						className="pf-c-form__helper-text pf-m-error"
						id="etl-source-database-database-name-helper"
						aria-live="polite"
					>
						This field is required.
					</div>
				</div>
			)}
		</div>
	);
}

export default RequiredFormLabel;
