import axiosConfig from '../axiosConfig';
import { GenericResponse } from '../types';

export type TAlertCalculation = {
	alert_definition_id: number;
	datapoints_met: number;
	date_met: string;
};

export class AlertCalculation implements TAlertCalculation {
	alert_definition_id: number;
	datapoints_met: number;
	date_met: string;

	constructor(alert: TAlertCalculation) {
		this.alert_definition_id = alert.alert_definition_id;
		this.datapoints_met = alert.datapoints_met;
		this.date_met = alert.date_met;
	}

	public static GetAll(): Promise<GenericResponse<TAlertCalculation[]>> {
		return axiosConfig.instance.get('app/alert/pending', {}).then((res) => {
			return (res.data || []) as GenericResponse<TAlertCalculation[]>;
		});
	}
}
