import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { faFileImport } from '@fortawesome/pro-regular-svg-icons';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { useMount } from 'react-use';
import { DimensionSource, TDimensionSource } from '../../api/analytics/DimensionSource';
import { Period, TPeriod } from '../../api/analytics/Period';
import { ETLSourceDatabase, TETLSourceDatabase } from '../../api/analytics/ETLSourceDatabase';
import { EOccurrence } from '../../api/analytics/KeyMeasureSource';
import { Dimension } from '../../api/analytics/Dimension';
import { AdminActions, DataImportEntityTypeEnum } from '../../api/admin/AdminActions';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import { timestampToMMDDYYYY } from '../../utilities';

export default function DimensionSources(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<TDimensionSource[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeDimensionSource, setActiveDimensionSource] = useState<TDimensionSource>(
		DimensionSource.Default() as unknown as TDimensionSource
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
	const [periods, setPeriods] = useState<TPeriod[]>([]);
	const [ETLSourceDatabases, setETLSourceDatabases] = useState<TETLSourceDatabase[]>([]);
	const [dimensions, setDimension] = useState<Dimension[]>([]);

	const selectedColumns: Column<TDimensionSource>[] = [
		{
			title: 'Title',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'ETL Source',
			columnName: 'ETLSourceDatabase',
			customAccessor: (item) => item.ETLSourceDatabase.host ?? 'N/A',
			sortable: false,
		},
		{
			title: 'Dimension',
			columnName: 'dimension',
			customAccessor: (item) => {
				return `${item.dimension.name}`;
			},
		},
		{
			title: 'Daily run interval',
			columnName: 'daily_run_interval',
			sortable: true,
		},
		{
			title: 'Next Run Date',
			columnName: 'next_run_date',
			customAccessor: (item) =>
				item.next_run_date ? timestampToMMDDYYYY(item.next_run_date) : '-',
			sortable: true,
		},
		{
			title: 'Run Sequence',
			columnName: 'run_sequence',
			sortable: true,
		},
		{
			title: 'Run group',
			columnName: 'run_group',
			sortable: true,
		},
	];

	const actions: Action<TDimensionSource>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveDimensionSource(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditDimensionSource,
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);
				DimensionSource.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setData((prev) => prev.filter((dimension) => dimension.id !== item.id));
						addToast('Dimension Source deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete dimension Source. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeleteDimensionSource,
		},
		{
			name: (
				<>
					Data Import <FontAwesomeIcon icon={faFileImport} />
				</>
			),
			callback: (item) => {
				AdminActions.DataImport(DataImportEntityTypeEnum.DIMENSION_SOURCE, item.id)
					.then(() => {
						addToast('Data import queued successfully.', AlertVariant.success);
					})
					.catch(() => {
						addToast(
							'An error occurred while trying to queue data import. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.CanTriggerDataImport,
		},
	];

	useMount(() => {
		Period.GetAll()
			.then((periods) => {
				setPeriods(periods);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load periods. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		ETLSourceDatabase.GetAll()
			.then((ETLSourceDatabases) => {
				setETLSourceDatabases(ETLSourceDatabases);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load ETL Source Databases. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		Dimension.GetAll()
			.then((dimensions) => {
				setDimension(dimensions);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load the dimensions Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Dimension Sources"
					pageDescription="Manage your Dimension Sources."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		DimensionSource.GetAll(['dimension', 'ETLSourceDatabase'])
			.then((dimensionSources) => {
				setData(dimensionSources);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load dimensions. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: TDimensionSource) => {
		const index = data.findIndex((dimensionSource) => dimensionSource.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const addButton = (
		<div>
			<PermissionButton
				data-testid={'dimension-source-all-import-button'}
				variant={'primary'}
				permission={Permission.CanTriggerDataImport}
				onClick={() => {
					AdminActions.DataImportDS(DataImportEntityTypeEnum.DIMENSION_SOURCE, 0).catch(
						() => {
							addToast(
								'An error occurred while trying to queue data import. Please try again later.',
								AlertVariant.danger
							);
						}
					);
				}}
			>
				Data Import All <FontAwesomeIcon icon={faFileImport} />
			</PermissionButton>
			<PermissionButton
				data-testid={'dimension-source-add-button'}
				variant={'primary'}
				permission={Permission.CreateDimensionSource}
				onClick={() => {
					setActiveDimensionSource(
						DimensionSource.Default() as unknown as TDimensionSource
					);
					setIsModalOpen(true);
				}}
			>
				New Dimension Source
			</PermissionButton>
		</div>
	);

	const formProperties: Field<TDimensionSource>[] = [
		{
			title: 'Title',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Dimension',
			columnName: 'dimension',
			uiSchema: {
				type: UIType.SELECT,
				helpText: 'The dimension you wish to run SQL against.',
				initialSelection:
					typeof activeDimensionSource.dimension === 'object'
						? activeDimensionSource.dimension.name
						: null,
				options: dimensions.map((dimension) => {
					return {
						key: dimension.id,
						value: dimension.name,
					};
				}),
				onSelect: (dimension: ISelectOption) => {
					return dimension.key;
				},
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
			required: true,
			maxLength: 255,
		},
		{
			title: 'ETL Source Database',
			columnName: 'ETLSourceDatabase',
			uiSchema: {
				type: UIType.SELECT,
				initialSelection:
					typeof activeDimensionSource.ETLSourceDatabase === 'object'
						? activeDimensionSource.ETLSourceDatabase.dbName
						: null,
				options: ETLSourceDatabases.map((etl) => {
					return {
						key: etl.id,
						value: etl.dbName ?? 'N/A',
					};
				}),
				onSelect: (etl: ISelectOption) => {
					return etl.key;
				},
			},
		},
		{
			title: 'SQL Statement',
			columnName: 'sql',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
			required: true,
		},
		{
			title: 'Run Occurrence',
			columnName: 'occurrence',
			uiSchema: {
				type: UIType.SELECT,
				options: [
					{
						key: EOccurrence.RECURRING,
						value: 'Recurring',
					},
					{
						key: EOccurrence.ONE_TIME,
						value: 'One Time',
					},
					{
						key: EOccurrence.DAILY,
						value: 'Daily',
					},
					{
						key: EOccurrence.NEVER,
						value: 'Never',
					},
				],
				onSelect: (selected: ISelectOption) => {
					return selected.key;
				},
			},
			required: true,
		},
		{
			title: 'Run Period',
			columnName: 'period',
			uiSchema: {
				type: UIType.SELECT,
				options: periods.map((period) => ({
					value: period.name,
					key: period.id,
				})),
				initialSelection:
					typeof activeDimensionSource.period === 'object'
						? activeDimensionSource.period.id
						: undefined,
				onSelect: (value: ISelectOption) => {
					return value.key;
				},
			},
			required: true,
		},
		{
			title: 'Daily Run Interval',
			columnName: 'daily_run_interval',
			uiSchema: {
				type: UIType.NUMBER,
				min: 0,
			},
		},
		{
			title: 'Start Date',
			columnName: 'start_date',
			uiSchema: {
				type: UIType.DATE,
			},
			required: true,
		},
		{
			title: 'End Date',
			columnName: 'end_date',
			uiSchema: {
				type: UIType.DATE,
			},
		},
		{
			title: 'Run group',
			columnName: 'run_group',
			uiSchema: {
				type: UIType.TEXT,
			},
		},
		{
			title: 'Run Sequence',
			columnName: 'run_sequence',
			uiSchema: {
				type: UIType.TEXT,
			},
		},
		{
			title: 'Next Run Date',
			columnName: 'next_run_date',
			uiSchema: {
				type: UIType.DATE,
				helpText: 'The date to run the next dimension source.',
			},
		},
	];

	const dimensionTable = (
		<ZiTable<TDimensionSource>
			ariaLabel={'Dimension Sources table'}
			columns={selectedColumns}
			data={data}
			caption="Dimension Sources"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Dimension Source Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TDimensionSource>
					title={'Dimension Source'}
					fields={formProperties}
					initialSubject={activeDimensionSource}
					isLoading={isFormLoading}
					onSubmit={(dimensionSource) => {
						setIsFormLoading(true);

						if ('id' in dimensionSource) {
							DimensionSource.Update(dimensionSource, [
								'dimension',
								'ETLSourceDatabase',
							])
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save dimension source. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							DimensionSource.New(dimensionSource, ['dimension', 'ETLSourceDatabase'])
								.then((newSource) => {
									handleSuccess(newSource);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save dimension  source. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={dimensionTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
