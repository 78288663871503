import _ from 'lodash';
import { TUnitType } from '../api/analytics/UnitType';

import { DataframeDataRetrievalResponse } from '../api/types';
import { ChartSeries } from '../types/charts/chart-options';
import { DraggableMenuItemData } from '../types/databuilder/databuilder';
import { IIndexable } from '../types/general';
import {
	formatClusteredBarData,
	formatClusteredColumnData,
	formatDefaultMultiSeriesData,
	formatHeatmapData,
	formatSankeyOrDepWheelMultiSeriesData,
} from './chart.multiSeries.helpter';
import {
	getColumnRangeData,
	getDefaultSingleSeriesData,
	getOdometerData,
} from './chart.singleSeries.helper';
import { MultipartResponse } from './multipart-response.helper';
import { PreviewGrandTotal } from '../types/charts/preview-grand-total';
import { getNumberByKey } from './general-helper-functions';

export type ChartDataResponse = {
	xAxisArray: string[];
	yAxisArray: string[];
	valueArray: string[];
	valueArray2: string[];
	seriesData: ChartSeries[];
	lookupData: (string | number)[][];
};

export const getChartSeriesData = (
	chartRetrevialData: IIndexable[],
	isSingleSeries: boolean,
	chartType?: string,
	unitType?: TUnitType,
	selected2ndUnitType?: TUnitType,
	selectedNumDecimals?: number,
	droppedFactsCount?: number,
	chartColor?: string,
	grandTotal?: [{ [key: string]: number }],
	showPercentages?: boolean,
	droppedFacts?: DraggableMenuItemData[]
) => {
	let response: ChartDataResponse = {
		xAxisArray: [],
		yAxisArray: [],
		seriesData: [],
		valueArray: [],
		valueArray2: [],
		lookupData: [[], []],
	};
	let numberOfDecimals = selectedNumDecimals ? selectedNumDecimals : 2;

	//multi-series
	if (!isSingleSeries) {
		numberOfDecimals = (
			showPercentages
				? 2
				: droppedFacts && droppedFacts?.length > 0
				? droppedFacts[0]?.data?.numDecimals
				: selectedNumDecimals ?? 2
		) as number;

		switch (chartType) {
			case 'clusteredbar':
				response = formatClusteredBarData(
					chartRetrevialData,
					unitType,
					numberOfDecimals,
					grandTotal,
					showPercentages
				);
				break;
			case 'clusteredline':
			case 'clusteredcolumn':
				response = formatClusteredColumnData(
					chartRetrevialData,
					unitType,
					numberOfDecimals,
					grandTotal,
					showPercentages
				);
				break;
			case 'heatmap':
				response = formatHeatmapData(
					chartRetrevialData,
					unitType,
					numberOfDecimals,
					grandTotal,
					showPercentages
				);
				break;
			case 'sankey':
			case 'dependencywheel':
				response = formatSankeyOrDepWheelMultiSeriesData(
					chartRetrevialData,
					unitType,
					numberOfDecimals,
					grandTotal
				);
				break;
			default:
				response = formatDefaultMultiSeriesData(
					chartRetrevialData,
					unitType,
					numberOfDecimals,
					grandTotal,
					showPercentages
				);
				break;
		}
	}
	//normal single-series
	else {
		switch (chartType) {
			case 'odometer':
				response = getOdometerData(
					chartRetrevialData,
					unitType,
					numberOfDecimals,
					grandTotal,
					showPercentages
				);
				break;
			case 'columnrange':
				response = getColumnRangeData(
					chartRetrevialData,
					unitType,
					numberOfDecimals,
					droppedFactsCount,
					chartColor,
					grandTotal,
					showPercentages,
					droppedFacts
				);
				break;
			default:
				response = getDefaultSingleSeriesData(
					chartRetrevialData,
					unitType,
					selected2ndUnitType,
					numberOfDecimals,
					droppedFactsCount,
					chartColor,
					grandTotal,
					showPercentages,
					droppedFacts
				);
		}
	}

	return response;
};

export const getPreviewDataAndCalculateGrandTotal = (
	dataSets: DraggableMenuItemData[],
	previewData: unknown[] | MultipartResponse<DataframeDataRetrievalResponse> | undefined,
	isChartView?: boolean,
	showAsPercentage?: boolean
): PreviewGrandTotal => {
	const grandTotal: [{ [key: string]: number }] = [{}];
	let previewDataAndTotal = <PreviewGrandTotal>{};
	let clone: MultipartResponse<DataframeDataRetrievalResponse> | undefined;

	if (isChartView) {
		dataSets?.forEach((fact) => {
			if (fact) {
				let factTotal = 0;

				(previewData as unknown[])?.forEach((col) => {
					const column = col as { [key: string]: string };

					if (fact.data) {
						factTotal += Number(column[fact.data.title.replace(/\s/g, '')]);
					}
				});

				if (fact.data) {
					grandTotal.push({ [`${fact.data.title.replaceAll(' ', '')}`]: factTotal });
				}
			}
		});
	} else {
		const data = previewData as MultipartResponse<DataframeDataRetrievalResponse> | undefined;
		clone = _.cloneDeep(data);

		dataSets?.forEach((fact) => {
			let factTotal = 0;
			let outerIndex = 0;
			let keyIndex = 0;

			data?.json?.columns?.forEach((col, outIndex) => {
				if (Array.isArray(col)) {
					col.forEach((innerCol, index) => {
						if (innerCol === fact.data?.title) {
							keyIndex = index;
							outerIndex = outIndex;
						}
					});
				}
			});

			data?.csvData?.forEach((dataItem) => {
				const splitItem = dataItem.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g);
				factTotal += +splitItem[keyIndex];
			});

			if (fact.data && showAsPercentage) {
				const columns = clone?.json?.columns[clone?.json?.columns.length - 1] ?? [];
				columns.push(`${fact.data.title} Percent of Total`);
			}

			if (clone) {
				if (showAsPercentage) {
					clone.csvData = clone?.csvData?.map((dataItem) => {
						const splitItem = dataItem.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g);
						const calcValue = (+splitItem[keyIndex] / factTotal) * 100;

						return `${dataItem},${calcValue}`;
					});
				}

				if (fact.data) {
					grandTotal.push({ [`${fact.data.title.replaceAll(' ', '')}`]: factTotal });
				}
			}
		});
	}

	grandTotal.shift();

	previewDataAndTotal = {
		previewData: clone,
		grandTotal: grandTotal,
	};

	return previewDataAndTotal;
};

export const calculatePercentage = (
	fact?: string,
	grandTotal?: [{ [key: string]: number }],
	yTotal?: number | null
): number | null => {
	const total = grandTotal ? getNumberByKey(grandTotal, fact?.replaceAll(' ', '')) : 0;
	const value = yTotal ? (total > 0 ? ((yTotal ? Number(yTotal) : 0) / total) * 100 : 0) : null;

	return value;
};
