import {
	Button,
	Modal,
	ModalBoxFooter,
	ModalVariant,
	Text,
	Alert,
	TextInput,
} from '@patternfly/react-core';
import { useState, useEffect } from 'react';
import { IconDefinition, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { QuestionCategory, TQuestionCategory } from '../../api/question-category/QuestionCategory';
import {
	faDiploma,
	faFileCertificate,
	faAward,
	faDollarSign,
	faPeopleGroup,
	faBuildingColumns,
	faListCheck,
	faClipboardListCheck,
	faBarsProgress,
	faCalendarDays,
	faCalendarImage,
	faPeopleArrows,
	faSitemap,
	faBuildingUser,
	faBuilding,
	faTransporter,
	faSparkles,
	faChartUser,
	faChartNetwork,
	faShieldCheck,
	faUserHelmetSafety,
	faMoneyCheckDollar,
	faFolderUser,
	faPersonArrowDownToLine,
	faPersonArrowUpFromLine,
	faClockDesk,
	faCalendarClock,
	faUserClock,
	faTruckClock,
	faHourglassClock,
	faPause,
	faGift,
	faHandshake,
	faTemperatureList,
	faLayerGroup,
	faChartArea,
	faChartSimpleHorizontal,
	faFilter,
	faGauge,
	faHeat,
	faChartLineUp,
	faChartPie,
	faCircleDot,
	faDiagramSankey,
	faChartScatter,
	faChartScatterBubble,
	faTable,
	faCloudWord,
	faChartSimple,
	faLollipop,
} from '@fortawesome/pro-regular-svg-icons';
import IconCardDropdown from './IconCardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
	isOpen: boolean;
	onClose: (hasAddedQuestionCat: boolean) => void;
};

function DiscoverAddQuestionCategoryModal(props: Props) {
	const [modalTitle, _setModalTitle] = useState(`Discover - Add Question Category`);
	const [buttonText, _setButtonText] = useState('Submit');
	const [categoryText, setcategoryText] = useState('');
	const [validationMessage, setValidationMessage] = useState<React.ReactElement | null>();
	const [_isExpanded, setIsExpanded] = useState(false);
	const [selectedCategoryIcon, setSelectedCategoryicon] = useState<IconDefinition>(faLayerGroup);
	const [libraryIcons, setLibraryIcons] = useState<IconDefinition[]>();
	const [isLoading, setIsLoading] = useState(false);
	const iconNames = [
		faDiploma,
		faFileCertificate,
		faAward,
		faDollarSign,
		faPeopleGroup,
		faBuildingColumns,
		faListCheck,
		faClipboardListCheck,
		faBarsProgress,
		faCalendarDays,
		faCalendarImage,
		faPeopleArrows,
		faSitemap,
		faBuildingUser,
		faBuilding,
		faTransporter,
		faSparkles,
		faChartUser,
		faChartNetwork,
		faShieldCheck,
		faUserHelmetSafety,
		faMoneyCheckDollar,
		faFolderUser,
		faPersonArrowDownToLine,
		faPersonArrowUpFromLine,
		faClockDesk,
		faCalendarClock,
		faUserClock,
		faTruckClock,
		faHourglassClock,
		faPause,
		faGift,
		faHandshake,
		faTemperatureList,
		faLayerGroup,
		faChartArea,
		faChartSimpleHorizontal,
		faFilter,
		faGauge,
		faHeat,
		faChartLineUp,
		faChartPie,
		faCircleDot,
		faDiagramSankey,
		faChartScatter,
		faChartScatterBubble,
		faTable,
		faCloudWord,
		faChartSimple,
		faLollipop,
	];

	const handleTextChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
		setcategoryText(value);
	};

	// Update actionText when the modal is opened
	useEffect(() => {
		if (props.isOpen) {
			setValidationMessage(null);
			setcategoryText('');
			setSelectedCategoryicon(faLayerGroup);
		}
	}, [props.isOpen]);

	const submitAddQuestionCat = () => {
		if (categoryText === '') {
			setValidationMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title={`Category Text is required`}
				/>
			);

			return;
		} else {
			SaveAddQuestionCat();

			props.onClose(true);
			props.onClose(true);
		}
	};

	const SaveAddQuestionCat = () => {
		const newQuestionCatRequest: TQuestionCategory = {
			description: '',
			title: categoryText,
			sequence: 1,
			icon: selectedCategoryIcon.iconName,
		};

		void QuestionCategory.New(newQuestionCatRequest);
	};

	useEffect(() => {
		const icons: IconDefinition[] = [];

		iconNames.map((iconName, index) => {
			icons.push(iconName);
		});
		setLibraryIcons(icons);

		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			const classes = target.classList;
			const isDropdownItemClicked =
				classes.contains('pf-c-dropdown__menu-item') ||
				classes.contains('pf-c-dropdown__toggle');
			if (!isDropdownItemClicked) {
				setIsExpanded(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div>
			<Modal
				title={modalTitle}
				variant={ModalVariant.medium}
				isOpen={props.isOpen}
				onClose={() => {
					props.onClose(false);
				}}
				className="add-question-cat-modal"
			>
				{validationMessage && (
					<div>
						<br />
						{validationMessage}
					</div>
				)}
				<span className="spacer-sm"></span>
				<Text>
					Question Category <span className="pf-m-danger">*</span>
				</Text>
				<TextInput
					value={categoryText}
					onChange={handleTextChange}
				/>
				<br />
				<br />
				<Text>Category icon</Text>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<IconCardDropdown
						iconElements={libraryIcons ?? []}
						setSelectedCategoryicon={setSelectedCategoryicon}
						selectedIcon={selectedCategoryIcon}
						setIsLoading={setIsLoading}
					/>

					{isLoading && (
						<FontAwesomeIcon
							icon={faSpinner}
							spin={true}
							size="2x"
						/>
					)}
				</div>
				<div>
					<ModalBoxFooter className="pull-right add-question-footer">
						<Button
							variant="secondary"
							onClick={() => {
								props.onClose(false);
							}}
						>
							Cancel
						</Button>
						<Button
							variant="primary"
							onClick={submitAddQuestionCat}
						>
							{buttonText}
						</Button>
					</ModalBoxFooter>
				</div>
			</Modal>
		</div>
	);
}

export default DiscoverAddQuestionCategoryModal;
