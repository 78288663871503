import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@patternfly/react-core';
import DefaultView, { DefaultViewProps } from './DefaultView';
import KeyMeasureView, { KeyMeasureViewProps } from './KeyMeasureView';
import DataframeView, { DataframeViewProps } from './DataFrameView';
import CopyView, { CopyViewProps } from './CopyView';
import TemplateView, { TemplateViewProps } from './TemplateView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import './ZiCreateSelectionModal.scss';
import { TKeyMeasure } from '../../../../api/analytics/KeyMeasure';
import { TDimension } from '../../../../api/analytics/Dimension';
import { TDataframe } from '../../../../api/types';
import { TKeyMeasureFact } from '../../../../api/analytics/KeyMeasureFact';
import { TDimensionAttribute } from '../../../../api/analytics/DimensionAttribute';
import { TFolder } from '../../../../api/foundational-elements/Folder';
import { TChart } from '../../../../api/charts/Chart';
import { useNavigate } from 'react-router-dom';
import { TReport } from '../../../../api/types';

export type ZiCreateSelectionOptionsModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: (fact: TKeyMeasureFact, dataframe?: TDataframe) => void;
	selectedValues?: ZiCreateSelectionModalSelectedValues;
	viewOptions: viewOptions[];
	defaultView: ViewTypesEnum;
	copyType?: CopyTypesEnum;
	dashboardId?: number;
	presentationId?: number;
	urlBase: string;
	title: string;
	navigateOnModalClose: boolean;
};

export type ZiCreateSelectionModalSelectedValues = {
	selectedKeyMeasure?: TKeyMeasure;
	selectedSeries?: TDimension;
	selectedFrame?: TDataframe;
};

export type viewOptions = {
	icon: IconDefinition;
	type: ViewTypesEnum;
	label: string;
	tooltip?: string;
};

export enum ViewTypesEnum {
	Default = 'default',
	Measure = 'measure',
	Dataframe = 'dataframe',
	Template = 'template',
	Copy = 'copy',
}

export enum CopyTypesEnum {
	Chart = 'chart',
	Report = 'report',
	Table = 'table',
}

const ZiCreateSelectionModal = (props: ZiCreateSelectionOptionsModalProps) => {
	const { viewOptions, defaultView, copyType, urlBase, title } = props;
	const [currentView, setCurrentView] = useState<ViewTypesEnum>(defaultView);

	const [selectedKeyMeasure, setSelectedKeyMeasure] = useState<TKeyMeasure>();
	const [selectedKeyMeasureFact, setSelectedKeyMeasureFact] = useState<TKeyMeasureFact>();
	const [selectedDimension, setSelectedDimension] = useState<TDimension>();
	const [selectedDataframe, setSelectedDataframe] = useState<TDataframe>();
	const [selectedDimensionAttribute, setSelectedDimensionAttribute] = useState<
		TDimensionAttribute | undefined
	>();
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const [selectedTemplate, setSelectedTemplate] = useState<any>();
	const [selectedFolder, setSelectedFolder] = useState<TFolder | undefined>();
	const [selectedChart, setSelectedChart] = useState<TChart | undefined>();
	const [selectedReport, setSelectedReport] = useState<TReport | undefined>();

	const [createDisabled, setCreateDisabled] = useState(true);

	const navigator = useNavigate();

	const setSelectedItem = (item: TChart | TReport) => {
		if (copyType === CopyTypesEnum.Chart) {
			setSelectedChart(item as TChart);
		} else if (copyType === CopyTypesEnum.Report || copyType == CopyTypesEnum.Table) {
			setSelectedReport(item as TReport);
		}
	};

	const defaultViewProps: DefaultViewProps = {
		onViewChange: setCurrentView,
		viewOptions: viewOptions,
		label: copyType ?? '',
	};

	const keyMeasureProps: KeyMeasureViewProps = {
		selectedMeasure: selectedKeyMeasure,
		selectedFact: selectedKeyMeasureFact,
		selectedDimension: selectedDimension,
		selectedDimensionAttribute: selectedDimensionAttribute,
		onSelectMeasure: setSelectedKeyMeasure,
		onSelectDimension: setSelectedDimension,
		onSelectFact: setSelectedKeyMeasureFact,
		onSelectDimensionAttribute: setSelectedDimensionAttribute,
		setCreateDisabled: setCreateDisabled,
		hideDimensionSelection: !props.navigateOnModalClose,
	};

	const dataframeProps: DataframeViewProps = {
		selectedFrame: selectedDataframe,
		onSelectFrame: setSelectedDataframe,
		onSelectMeasure: setSelectedKeyMeasure,
		onSelectFact: setSelectedKeyMeasureFact,
		allowFactSelection: !props.navigateOnModalClose,
	};

	const copyProps: CopyViewProps = {
		onSelectItem: setSelectedItem,
	};

	const templateProps: TemplateViewProps = {
		onSelectTemplate: setSelectedTemplate,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		selectedTemplate: selectedTemplate,
	};

	const getView = (view: ViewTypesEnum): JSX.Element => {
		switch (view) {
			case 'default':
				return <DefaultView {...defaultViewProps} />;
			case 'measure':
				return <KeyMeasureView {...keyMeasureProps} />;
			case 'dataframe':
				return <DataframeView {...dataframeProps} />;
			case 'template':
				return <TemplateView {...templateProps} />;
			case 'copy':
				return <CopyView {...copyProps} />;
			default:
				return <DefaultView {...defaultViewProps} />;
		}
	};

	useEffect(() => {
		if (currentView === 'measure') {
			if (!props.navigateOnModalClose) {
				setCreateDisabled(false);
			} else {
				setCreateDisabled(true);
			}
			return;
		}

		if (currentView === 'dataframe') {
			if (!props.navigateOnModalClose) {
				setCreateDisabled(selectedKeyMeasureFact === void 0);
			} else {
				setCreateDisabled(selectedDataframe === void 0);
			}
			return;
		}

		if (currentView === 'copy') {
			if (copyType === CopyTypesEnum.Chart) {
				setCreateDisabled(selectedChart === void 0);
			} else if (copyType === CopyTypesEnum.Report) {
				setCreateDisabled(selectedReport === void 0);
			}
			return;
		}

		if (currentView === 'template') {
			setCreateDisabled(selectedTemplate === void 0);
			return;
		}

		setCreateDisabled(true);
	}, [selectedKeyMeasureFact, selectedDataframe, selectedChart, selectedReport]);

	const beforeClose = () => {
		setCurrentView(defaultView);
		props.onClose();
	};

	const beforeCreate = () => {
		setCurrentView(defaultView);

		if (props.navigateOnModalClose) {
			const queryParams: string[] = [];

			if (selectedKeyMeasure) {
				queryParams.push(`measureId=${selectedKeyMeasure.id}`);
			}

			if (selectedKeyMeasureFact) {
				queryParams.push(`measureFactId=${selectedKeyMeasureFact.id}`);
			}

			if (selectedDimension) {
				queryParams.push(`dimensionId=${selectedDimension.id}`);
			}

			if (selectedDimensionAttribute) {
				queryParams.push(`dimensionAttributeId=${selectedDimensionAttribute.id}`);
			}

			if (selectedDataframe) {
				queryParams.push(`dataframeId=${selectedDataframe.id}`);
			}

			if (selectedReport) {
				queryParams.push(`reportId=${selectedReport.id}`);
			}

			beforeClose();

			navigator({
				pathname: urlBase,
				search: `?${queryParams.join('&')}`,
			});
		} else {
			// TODO: expand in future to support dimensions and dim attributes
			if (props.onSuccess) {
				props.onSuccess(selectedKeyMeasureFact!, selectedDataframe);
			}
		}
	};

	const setDefaultView = (): void => {
		setCurrentView(defaultView);
		setSelectedKeyMeasure(undefined);
		setSelectedKeyMeasureFact(undefined);
		setSelectedDimension(undefined);
		setSelectedDimensionAttribute(undefined);
		setSelectedDataframe(undefined);
		setSelectedReport(undefined);
		setSelectedTemplate(undefined);
	};

	const backIcon = (
		<div className="back-btn">
			<Button
				variant="link"
				onClick={setDefaultView}
			>
				<FontAwesomeIcon icon={faArrowLeft} />
			</Button>
		</div>
	);

	return (
		<>
			<Modal
				data-testid="create-selection-modal"
				isOpen={props.isOpen}
				onClose={beforeClose}
				variant="small"
				title={title}
				actions={
					currentView === defaultView
						? []
						: [
								<Button
									data-testid="create-button"
									key="create"
									variant="primary"
									onClick={beforeCreate}
									isDisabled={createDisabled}
								>
									{props.navigateOnModalClose ? 'Create' : 'Add'}
								</Button>,
								<Button
									data-testid="cancel-button"
									key="cancel"
									variant="link"
									onClick={beforeClose}
								>
									Cancel
								</Button>,
						  ]
				}
			>
				{currentView !== defaultView ? backIcon : null}
				{getView(currentView)}
			</Modal>
		</>
	);
};

export default ZiCreateSelectionModal;
