import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { useMount } from 'react-use';
import { Group, TGroup, TNewGroup } from '../../api/security/Group';
import { User, TUser } from '../../api/security/User';
import { Dimension, TDimension } from '../../api/analytics/Dimension';
import { DimensionAttribute, TDimensionAttribute } from '../../api/analytics/DimensionAttribute';
import { KeyMeasure, TKeyMeasure } from '../../api/analytics/KeyMeasure';
import { Column } from '@zeroedin-tech/zi-common-ui/lib/';
import FilterTableLayout from '../../layout/FilterTableLayout';
import ZiTable, { Action } from '../../components/table/ZiTable';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalBoxFooter, ModalVariant } from '@patternfly/react-core';
import SchnurForm, {
	Field,
	IDualListOption,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
export default function Groups(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [tableData, setTableData] = useState<TGroup[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isGroupDeleteModalOpen, setIsGroupDeleteModalOpen] = useState<boolean>(false);
	const [activeGroup, setActiveGroup] = useState<TGroup>(Group.Default() as TGroup);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [users, setUsers] = useState<TUser[]>([]);
	const [dimensions, setDimensions] = useState<TDimension[]>([]);
	const [dimensionAttributes, setDimensionAttributes] = useState<TDimensionAttribute[]>([]);
	const [keyMeasures, setKeyMeasures] = useState<TKeyMeasure[]>([]);

	const selectedColumns: Column<TGroup>[] = [
		{
			title: 'Name',
			columnName: 'name',
		},
		{
			title: 'Description',
			columnName: 'description',
		},
		{
			title: 'Tag',
			columnName: 'tag',
		},
		{
			title: 'Users',
			columnName: 'users',
			customAccessor: (item) => `${item.users.length} users`,
			sortable: true,
		},
		{
			title: 'Key Measures',
			columnName: 'keyMeasures',
			customAccessor: (item) => `${item.keyMeasures.length} key measures`,
			sortable: true,
		},
		{
			title: 'Dimensions',
			columnName: 'dimensions',
			customAccessor: (item) => `${item.dimensions.length} dimensions`,
			sortable: true,
		},
		{
			title: 'Dimension Attributes',
			columnName: 'dimensionAttributes',
			customAccessor: (item) => `${item.dimensionAttributes.length} dimension attributes`,
			sortable: true,
		},
	];

	const actions: Action<TGroup>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveGroup(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditGroup,
		},
		{
			name: 'Delete',
			callback: (item) => {
				if (item.users.length == 0) {
					setActiveGroup(item);
					setIsGroupDeleteModalOpen(true);
				} else {
					addToast(
						'Cannot delete a User Group with assigned users.',
						AlertVariant.danger
					);
				}
			},
			permission: Permission.DeleteUnitType,
		},
	];

	const formProperties: Field<TNewGroup>[] = [
		{
			title: 'Group Name',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The name of the Group.',
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
				placeholder: 'Key Measure Description',
				helpText: 'The description of the Key Measure to display in the UI.',
			},
			maxLength: 255,
		},
		{
			title: 'Group Tag',
			columnName: 'tag',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The tag used to identify the group.',
			},
			required: true,
		},
		{
			title: 'Users',
			columnName: 'users',
			uiSchema: {
				type: UIType.DUAL_LIST,
				options: users.map((user) => ({ key: user.id, value: user.name } as ISelectOption)),
				onSelect: (value: IDualListOption[]) => {
					return [...value.map((option) => Number(option.key))];
				},
				selected: activeGroup.users
					.map((groupUser) => {
						if (typeof groupUser === 'object') {
							return {
								key: groupUser.id,
								value: groupUser.name,
							} as ISelectOption;
						} else if (typeof groupUser === 'number') {
							const foundUser = users.find((user) => user.id === groupUser);
							return {
								key: foundUser?.id,
								value: foundUser?.name,
							} as ISelectOption;
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[],
			},
		},
		{
			title: 'KeyMeasures',
			columnName: 'keyMeasures',
			uiSchema: {
				type: UIType.DUAL_LIST,
				options: keyMeasures.map(
					(keyMeasure) =>
						({ key: keyMeasure.id, value: keyMeasure.name } as ISelectOption)
				),
				onSelect: (value: IDualListOption[]) => {
					return [...value.map((option) => Number(option.key))];
				},
				selected: activeGroup.keyMeasures
					.map((measure) => {
						if (typeof measure === 'object') {
							return {
								key: measure.id,
								value: measure.name,
							} as ISelectOption;
						} else if (typeof measure === 'number') {
							const km = keyMeasures.find((m) => m.id === measure);
							return {
								key: km?.id,
								value: km?.name,
							} as ISelectOption;
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[],
			},
		},
		{
			title: 'Dimensions (To Exclude)',
			columnName: 'dimensions',
			uiSchema: {
				type: UIType.DUAL_LIST,
				options: dimensions.map(
					(dimension) => ({ key: dimension.id, value: dimension.name } as ISelectOption)
				),
				onSelect: (value: IDualListOption[]) => {
					return [...value.map((option) => Number(option.key))];
				},
				selected: activeGroup.dimensions
					.map((groupDim) => {
						if (typeof groupDim === 'object') {
							return {
								key: groupDim.id,
								value: groupDim.name,
							} as ISelectOption;
						} else if (typeof groupDim === 'number') {
							const foundDim = dimensions.find((dim) => dim.id === groupDim);
							return {
								key: foundDim?.id,
								value: foundDim?.name,
							} as ISelectOption;
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[],
			},
		},
		{
			title: 'Dimension Attributes (To Exclude)',
			columnName: 'dimensionAttributes',
			uiSchema: {
				type: UIType.DUAL_LIST,
				options: dimensionAttributes.map(
					(dimensionAttribute) =>
						({
							key: dimensionAttribute.id,
							value: dimensionAttribute.name,
						} as ISelectOption)
				),
				onSelect: (value: IDualListOption[]) => {
					return [...value.map((option) => Number(option.key))];
				},
				selected: activeGroup.dimensionAttributes
					.map((attribute) => {
						if (typeof attribute === 'object') {
							return {
								key: attribute.id,
								value: attribute.name,
							} as ISelectOption;
						} else if (typeof attribute === 'number') {
							const dimAttr = dimensionAttributes.find(
								(dimAttr) => dimAttr.id === attribute
							);
							return {
								key: dimAttr?.id,
								value: dimAttr?.name,
							} as ISelectOption;
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[],
			},
		},
	];

	useMount(() => {
		Group.GetAll(['users', 'keyMeasures', 'dimensions', 'dimensionAttributes'])
			.then((groups) => {
				setTableData(groups);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to fetch Groups. Please try again later.',
					AlertVariant.danger
				);
			});

		User.GetAll()
			.then((user) => {
				setUsers(user);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to this users user. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		KeyMeasure.GetAll()
			.then((keyMeasure) => {
				setKeyMeasures(keyMeasure);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to this keyMeasures keyMeasure. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		Dimension.GetAll()
			.then((dimensions) => {
				setDimensions(dimensions);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load dimensions. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		DimensionAttribute.GetAll()
			.then((dimensionAttributes) => {
				setDimensionAttributes(dimensionAttributes);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load dimensions. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Groups"
					pageDescription="Manage Group users and data."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const addButton = (
		<PermissionButton
			data-testid={'group-create'}
			variant={'primary'}
			permission={Permission.CreateGroup}
			onClick={() => {
				setActiveGroup(Group.Default() as TGroup);
				setIsModalOpen(true);
			}}
		>
			New Group
		</PermissionButton>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleDeleteModalClose = () => {
		setIsGroupDeleteModalOpen(false);
	};

	const handleDelete = () => {
		setTableLoading(true);
		Group.Delete(activeGroup.id)
			.then(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				setTableData((prev) => prev.filter((user) => user.id !== activeGroup.id));
				addToast('User Group deleted successfully.', AlertVariant.success);
			})
			.catch(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				addToast(
					'An error occurred while trying to delete User Group. Please try again later.',
					AlertVariant.danger
				);
			});
	};

	const handleSuccess = (value: TGroup) => {
		const index = tableData.findIndex((row) => row.id === value.id);

		if (index >= 0) {
			setTableData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setTableData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const groupTable = (
		<ZiTable<TGroup>
			ariaLabel={'User Groups'}
			columns={selectedColumns}
			data={tableData}
			caption="User Groups"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="User Group Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TGroup>
					title={'System Group Management'}
					fields={formProperties}
					initialSubject={activeGroup}
					isLoading={isFormLoading}
					onSubmit={(group) => {
						setIsFormLoading(true);

						if (group.id) {
							Group.Update(group, [
								'users',
								'keyMeasures',
								'dimensions',
								'dimensionAttributes',
							])
								.then((updated) => {
									if (updated.id) {
										handleSuccess(updated);
									} else {
										setIsModalOpen(false);
									}
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the User Group. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							Group.New(group, [
								'users',
								'keyMeasures',
								'dimensions',
								'dimensionAttributes',
							])
								.then((newGroup) => {
									handleSuccess(newGroup);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the user Group. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<Modal
				title="User Group Delete Confirmation"
				variant="small"
				isOpen={isGroupDeleteModalOpen}
				onClose={handleDeleteModalClose}
				className="delete-modal"
			>
				<hr />
				<br />
				<div className="text-center">
					<h3>Are you sure you want to delete {activeGroup.name}?</h3>
				</div>
				<br />
				<hr />
				<ModalBoxFooter className="pull-right add-question-footer">
					<Button
						variant="secondary"
						onClick={handleDeleteModalClose}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleDelete}
					>
						Delete
					</Button>
				</ModalBoxFooter>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={groupTable}
					layoutActions={addButton}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
