import { buildUrlWithFilters } from '../../helpers/apiHelper';
import axiosConfig from '../axiosConfig';
import { TSharedEntityGroup } from './SharedEntityGroup';
import { TSharedEntityUser } from './SharedEntityUser';

export type TSharedEntity = {
	id?: number;
	entity_type: string;
	chart?: number;
	alert_definition?: number;
	dashboard?: number;
	report?: number;
	dataframe?: number;
	presentation?: number;
	folder?: number;
	table?: number;
	shared_entity_users?: TSharedEntityUser[];
	shared_entity_groups?: TSharedEntityGroup[];
};

export class SharedEntity implements TSharedEntity {
	id?: number;
	entity_type: string;
	chart?: number;
	alert_definition?: number;
	dashboard?: number;
	report?: number;
	dataframe?: number;
	presentation?: number;
	folder?: number;
	table?: number;
	shared_entity_users?: TSharedEntityUser[];
	shared_entity_groups?: TSharedEntityGroup[];

	constructor(sharedEntity: SharedEntity) {
		this.id = sharedEntity.id;
		this.entity_type = sharedEntity.entity_type;
		this.chart = sharedEntity.chart;
		this.alert_definition = sharedEntity.alert_definition;
		this.dashboard = sharedEntity.dashboard;
		this.report = sharedEntity.report;
		this.dataframe = sharedEntity.dataframe;
		this.presentation = sharedEntity.presentation;
		this.folder = sharedEntity.folder;
		this.shared_entity_users = sharedEntity.shared_entity_users;
		this.shared_entity_groups = sharedEntity.shared_entity_groups;
	}

	public static Default(): TSharedEntity {
		return {
			id: 0,
			entity_type: '',
		};
	}

	public static Get(id: number): Promise<TSharedEntity> {
		return axiosConfig.instance.get(`app/shared-entity/${id}`).then((response) => {
			return response.data as TSharedEntity;
		});
	}

	public static New(role: TSharedEntity): Promise<TSharedEntity> {
		return axiosConfig.instance.post('app/shared-entity', role).then((res) => {
			return new SharedEntity(res.data as TSharedEntity);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/shared-entity/${id}`).then(() => {
			return true;
		});
	}

	public static Update(role: TSharedEntity): Promise<TSharedEntity> {
		return axiosConfig.instance.patch('app/shared-entity', role).then((res) => {
			return new SharedEntity(res.data as TSharedEntity);
		});
	}

	public static GetAll(params: Record<string, string>): Promise<TSharedEntity[]> {
		const urlStart = 'app/shared-entity';
		const url = buildUrlWithFilters(urlStart, params);

		return axiosConfig.instance.get(url).then((res) => {
			return ((res.data || []) as TSharedEntity[]).map((role: TSharedEntity) => {
				return new SharedEntity(role);
			});
		});
	}

	public static GetPermissionForEntity(
		entityType: string,
		entityId: number,
		userId: number
	): Promise<TSharedEntityUser> {
		return axiosConfig.instance
			.post(`app/shared-entity/user-permission`, {
				entityType,
				entityId,
				userId,
			})
			.then((response) => {
				return response.data as TSharedEntityUser;
			});
	}
}
