import React, { ReactElement, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	DatePicker,
	Form,
	FormGroup,
	Select,
	SelectOption,
	SelectOptionObject,
	SelectVariant,
} from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { useApplication } from '../../components/user/ApplicationProvider';
import { faWarning } from '@fortawesome/pro-regular-svg-icons';
import { useMount } from 'react-use';
import { KeyMeasure } from '../../api/analytics/KeyMeasure';
import RequiredFormLabel from '../../components/form/RequiredFormLabel';
import DeleteConfirmationModal from '../../helpers/helper-components/DeleteConfirmationModal';

export default function PurgeKeyMeasures(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const { measures } = useApplication();
	const [isSelectOpen, setIsSelectOpen] = React.useState(false);
	const [selectedOption, setSelectedOption] = React.useState('');
	const [selectedOptionName, setSelectedOptionName] = React.useState<string>();
	const [date1, setDate1] = React.useState('');
	const [date2, setDate2] = React.useState('');
	const [measureOptions, setMeasureOptions] = React.useState<JSX.Element[]>([]);
	const [isFormError, setIsFormError] = React.useState<boolean>(false);
	const [isConfirmModelOpen, setIsConfirmModelOpen] = React.useState(false);

	useMount(() => {
		const list = measures.map((x) => (
			<SelectOption
				key={x.id.toString()}
				value={x.id.toString()}
				id={x.id.toString()}
				data-name={x.display_name ?? x.name}
			>
				{x.display_name ?? x.name}
			</SelectOption>
		));

		setMeasureOptions(list);
	});

	const onToggleSelect = (isOpen: boolean) => setIsSelectOpen(isOpen);

	const onSelectOption = (
		event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
		value: string | SelectOptionObject
	) => {
		const selectedOptionElement = event.target as HTMLElement;
		const dimensionName = selectedOptionElement?.getAttribute('data-name') || '';

		const id = value.toString();
		setSelectedOption(id);
		setSelectedOptionName(dimensionName);
		setIsSelectOpen(false);
	};

	const handleDateChange1 = (date: string) => setDate1(date);
	const handleDateChange2 = (date: string) => setDate2(date);

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Purge Key Measures"
					pageDescription="Purge Key Measure Data from your Database."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const submitPurgeKeyMeasure = (bypassDateFields = false) => {
		if ((date1 && !date2) || (!date1 && date2)) {
			addToast(
				'If you selected a Start Date Range then you have to specify an End Date Range as well and Vice Versea',
				AlertVariant.danger
			);
			return;
		}

		if (selectedOption == '' || selectedOptionName == '') {
			setIsFormError(true);
			return;
		} else {
			setIsFormError(false);
		}

		if (!date1 && !date2 && !bypassDateFields) {
			setIsConfirmModelOpen(true);
			return;
		}

		let unixTimestamp = date1 ? new Date(date1).getTime() : 0;
		const unixStartDate = Math.floor(unixTimestamp / 1000);

		unixTimestamp = date2 ? new Date(date2).getTime() : 0;
		const unixEndDate = Math.floor(unixTimestamp / 1000);

		KeyMeasure.Purge(+selectedOption, unixStartDate, unixEndDate)
			.then((response) => {
				if (
					response.success &&
					response.success == true &&
					!response.message.toLowerCase().includes('error')
				) {
					setSelectedOption('');
					setSelectedOptionName(undefined);
					setDate1('');
					setDate2('');
				} else {
					addToast(
						'An error occurred while trying to Purge Key Measures. Please try again later.',
						AlertVariant.danger
					);
				}
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to Purge Key Measures. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				if (isConfirmModelOpen == true) {
					setIsConfirmModelOpen(false);
				}
			});
	};

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className="pf-c-alert pf-m-inline pf-m-warning">
						<p className="pf-c-alert__title display-inline">
							<FontAwesomeIcon
								size="2x"
								icon={faWarning}
								className="bolder"
							/>
							<span className="nudge-right"></span>
							<div className="fs-large">
								Warning! This utility will permanently delete the selected key
								measure data
							</div>
						</p>
					</div>
					<br />
					<Form>
						<FormGroup
							label={
								<>
									Key Measure <span className="pf-m-danger">*</span>
								</>
							}
							fieldId="dropdown-field"
						>
							<Select
								id="dropdown-field"
								variant={SelectVariant.single}
								onToggle={onToggleSelect}
								onSelect={(event, value) => onSelectOption(event, value)}
								placeholderText={selectedOptionName ?? 'Select a Key Measure'}
								isOpen={isSelectOpen}
								selections={selectedOption}
								aria-label="Dropdown"
							>
								{measureOptions}
							</Select>
							<RequiredFormLabel isFormError={isFormError} />
						</FormGroup>

						<FormGroup
							label="Begin Date Range:"
							fieldId="date-picker-1"
						>
							<DatePicker
								id="date-picker-1"
								value={date1}
								onChange={(e, value) => handleDateChange1(value)}
								aria-label="Date Picker 1"
								inputProps={{ readOnly: true }}
							/>
						</FormGroup>

						<FormGroup
							label="End Date Range"
							fieldId="date-picker-2"
						>
							<DatePicker
								id="date-picker-2"
								value={date2}
								onChange={(e, value) => handleDateChange2(value)}
								aria-label="Date Picker 2"
								inputProps={{ readOnly: true }}
							/>
						</FormGroup>

						<div className="submit-button-container">
							<Button
								type="button"
								variant="primary"
								onClick={() => submitPurgeKeyMeasure()}
							>
								Submit
							</Button>
						</div>
					</Form>
				</CardBody>
			</Card>
			<DeleteConfirmationModal
				isOpen={isConfirmModelOpen}
				onClose={() => setIsConfirmModelOpen(false)}
				onSubmit={() => submitPurgeKeyMeasure(true)}
				titleText="Purge Key Measure Confirmation"
				textLabel="By not selecting a date range, you will delete ALL data records for the selected key measure, Please confirm your request"
				cancelLabel="No"
				confirmLabel="Yes"
			/>
		</React.Fragment>
	);
}
