import React, { ReactElement, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../layout/Layout';
import { Card, CardBody, Text, TextContent, TextVariants } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
export default function GettingStarted(): ReactElement {
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Setup"
					pageDescription="Configure your needed infrastructure and configuration for building data artifacts and gathering insights."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	return (
		<React.Fragment>
			<Card style={{ height: '100%' }}>
				<CardBody className="text-center">
					<TextContent>
						<FontAwesomeIcon
							icon={faCog}
							size="4x"
						/>
						<br />
						<br />
						<Text component={TextVariants.p}>
							<strong>Setup</strong> is your configuration zone.
						</Text>
						<Text component={TextVariants.p}>
							Set Dimensions, Attributes, and Key Measures in{' '}
							<strong>Analytics</strong>,
							<br />
							Data Sources in <strong>Integration Tools</strong>, Notifications, Unit
							Types and Categories in <strong>Settings</strong>,
							<br />
							Users, Roles and Groups in <strong>Security</strong>, and manage Cache
							and Job Queue in <strong>System Tools</strong>.
						</Text>
					</TextContent>
				</CardBody>
			</Card>
		</React.Fragment>
	);
}
